import {API_TOKEN} from "../redux/actions/authAction";
import {COOKIE_DOMAIN, IS_MOBILE_APP, MOBILE_LOGOUT_PAGE_URL, OUTER_PAGE_URL} from "./constants";
import Cookies from "js-cookie";
import {getCookie} from "./helpers";

export const speak = (word) => {
    const message = new SpeechSynthesisUtterance(word);
    message.lang = 'en-US';
    window.speechSynthesis.cancel();
    window.speechSynthesis.speak(message);
}

export const setCookie = (name = "", value = "") => {
    if (name && value) {
        Cookies.set(name, value, {expires: 100, domain: COOKIE_DOMAIN})
    } else {
        Cookies.remove(name, {domain: COOKIE_DOMAIN})
    }
}

export const logout = () => {
    setCookie(API_TOKEN, "");

    if (isMobileApp()) {
        window.location.href = MOBILE_LOGOUT_PAGE_URL;
    } else {
        window.location.href = OUTER_PAGE_URL;
    }
}

export const isMobileApp = () => {
    return parseInt(getCookie(IS_MOBILE_APP)) === 1 ? true : false;
}