import React, {useEffect} from 'react';
import './pageHead.scss';

const Testing = (props) => {
    const {data} = props;
    return (
        <div className="wt-head">
            {
                data.img &&
                <div className="left">
                    <img src={data?.img}/>
                </div>
            }
            <div className="right">
                <div className="title">{data.title}</div>
                <div className="count">
                    <div className="test" dangerouslySetInnerHTML={{__html: data.subTitle}}></div>
                </div>
                <div className="desc">{data.desc}</div>
            </div>
        </div>
    );
};

export default Testing;