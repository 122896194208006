import axios from "axios";
import {API_URL} from "../../commons/constants";
import update from "immutability-helper";
import {setTopics, setUserTopics} from "./topicsActions";
import {setUserPatterns, UPDATE_USER_PATTERNS} from "./patternsAction";
import {setUserVocabularies} from "./vocabulariesAction";
import {setUserLevel} from "./levelsAction";
import {setUserTests, UPDATE_USER_TESTS} from "./testsAction";

export const SET_COURSES = "SET_COURSES";
export const SET_ACTIVE_COURSE = "SET_ACTIVE_COURSE";
export const UPDATE_USER_DAILY_GUIDE = "UPDATE_USER_DAILY_GUIDE";
export const SET_ALL_MATERIALS_COUNT = "SET_ALL_MATERIALS_COUNT";

export const enrollCourse = (level) => {
    return async (dispatch, getState) => {
        let response = await axios.post(API_URL + '/courses/enroll', {level_id: level.id}, {
            headers: {Authorization: `Bearer ${getState().userReducer.apiToken}`}
        });

        response = response.data.data;
        if (response) {
            dispatch(setCourses([response]))
            dispatch(setActiveCourse(response))
            dispatch(setTopics({}));
        }
    }
}

export const setActiveCourse = (course) => {
    return async (dispatch, getState) => {

        dispatch(setUserLevel(course.level_id));
        dispatch(setUserTopics(course.topics));
        dispatch(setUserVocabularies(course.vocabularies));
        dispatch(setUserPatterns(course.daily_patterns))
        dispatch(setUserTests(course.tests))
        dispatch(setUserDailyGuide(course.daily_guide))

        dispatch({
            type: SET_ACTIVE_COURSE,
            value: course
        });
    }
}

export const setCourses = (newCourses) => {
    return async (dispatch, getState) => {
        let courses = getState().userCoursesReducer.courses;
        newCourses.forEach(function (course) {
            if (course.is_active) {
                dispatch(setUserLevel(course.level_id));
                dispatch(setActiveCourse(course))
            }
            if (!courses[course.id]) {
                courses = update(courses, {[course.id]: {$set: course}});
            }
        })
        dispatch({
            type: SET_COURSES,
            value: courses
        })
    }

}

export const fetchCourses = (level) => {
    return async (dispatch, getState) => {
        let response = await axios.get(API_URL + '/courses', {
            headers: {Authorization: `Bearer ${getState().userReducer.apiToken}`}
        });

        response = response.data.data;
        if (response) {
            dispatch(setCourses(response))
        }
    }
}

export const doneContent = (topicId, contentId = "") => {
    return async (dispatch, getState) => {
        let next = true;

        //tamamlamışsa ders partı boşuna istek atmasın
        const contentTopics = getState().topicsReducer.userTopics;
        if (contentTopics[topicId]) {
            const content = contentTopics[topicId]["contents"][contentId];
            if (content) {
                if (content["complete_date"]) {
                    next = false;
                }
            }
        }

        if (next) {
            let response = await axios.post(API_URL + '/courses/done-content', {topic_id: topicId, content_id: contentId}, {
                headers: {Authorization: `Bearer ${getState().userReducer.apiToken}`}
            });
            if (response.data) {
                dispatch(setActiveCourse(response.data.user_course));
            }
        }
    }
}

export const setUserDailyGuide = (userDailyGuide) => {
    return async (dispatch, getState) => {
        userDailyGuide = userDailyGuide ? JSON.parse(userDailyGuide) : {};

        dispatch(updateUserDailyGuide(userDailyGuide))
    }
}

export const updateUserDailyGuide = (userDailyGuide) => {
    return {
        type: UPDATE_USER_DAILY_GUIDE,
        value: userDailyGuide
    }
}

export const markUserDailyGuide = (type) => {
    return async (dispatch, getState) => {
        let response = await axios.post(API_URL + '/update-user-daily-guide', {type}, {
            headers: {Authorization: `Bearer ${getState().userReducer.apiToken}`}
        });

        dispatch(setUserDailyGuide(response.data.user_daily_guide))
    }
}


export const fetchAllMaterialsCount = () => {
    return async (dispatch, getState) => {
        let response = await axios.get(API_URL + '/all-materials-count', {
            headers: {Authorization: `Bearer ${getState().userReducer.apiToken}`}
        });

        dispatch(setAllMaterialsCount(response.data))
    }
}

export const setAllMaterialsCount = (allMaterialsCount) => {
    return {
        type: SET_ALL_MATERIALS_COUNT,
        value: allMaterialsCount
    }
}
