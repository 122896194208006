import {Button, Modal, Form, Input, Radio, Spin} from 'antd';
import {useState, useEffect} from 'react';
import './feedback.scss';
import {Icon} from '@iconify/react';
import {connect} from "react-redux";
import {newFeedback} from "../../redux/actions/feedbackAction";
import {useLocation} from 'react-router-dom';

const {detect} = require('detect-browser');
const {TextArea} = Input;

const points = {
    "1": "Kötü",
    "2": "Kararsız",
    "3": "Süper",
}

const categories = {
    "1": "Öneri",
    "2": "Geliştirme",
    "3": "Hata",
}

const Feedback = (props) => {
    const {newFeedback} = props;
    const [feedbackModal, setFeedbackModal] = useState(false);
    const [point, setPoint] = useState("");
    const [comment, setComment] = useState("");
    const [categoryId, setCategoryId] = useState("");
    const [load, setLoad] = useState(false);

    const location = useLocation();

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const showFeedback = urlParams.get('feedback');
        if (showFeedback) {
            setFeedbackModal(true);
        }
    }, [location]);

    const formSubmit = async () => {
        if (!load) {
            setLoad(true);
            if (point && comment && categoryId) {
                const browser = detect();
                browser["url"] = window.location.href;
                browser["size"] = {
                    "width": window.screen.width,
                    "height": window.screen.height,
                };

                await newFeedback({point, comment, categoryId, categoryName: categories[categoryId], details: browser});
                Modal.success({
                    title: 'Başarılı',
                    content: 'Geri dönüşünüz için teşekkürler.',
                    maskClosable: true,
                });

                setPoint("");
                setComment("");
                setCategoryId(null);
                setFeedbackModal(false);
            } else {
                Modal.warning({
                    title: 'Dikkat',
                    content: 'Lütfen tüm alanları doldurduktan sonra tekrar deneyin.',
                    maskClosable: true,
                });
            }
            setLoad(false);
        }
    }

    return (
        <>
            <Button className="global-info-btn" type="primary" onClick={() => setFeedbackModal(true)}>
                <Icon icon="ion:help-outline"/>
            </Button>
            <Modal
                centered
                open={feedbackModal}
                onCancel={() => setFeedbackModal(false)}
                onOk={() => formSubmit()}
                cancelText="Kapat"
                okText={load ? <Spin/> : "Tamam"}
                okButtonProps={{disabled: load}}
                className="feedback"
            >
                <Form>
                    <h3>Aşşağıdaki ifadelerden birini seçerek sayfa hakkında memnuniyet durumunu seçebilirsin.</h3>
                    <div className="points">
                        <div onClick={() => setPoint(1)} className={`${point === 1 ? "active" : ""}`}><Icon icon="emojione:sad-but-relieved-face"/></div>
                        <div onClick={() => setPoint(2)} className={`${point === 2 ? "active" : ""}`}><Icon icon="emojione:neutral-face"/></div>
                        <div onClick={() => setPoint(3)} className={`${point === 3 ? "active" : ""}`}><Icon icon="emojione:beaming-face-with-smiling-eyes"/></div>
                    </div>
                    <div className="comment">
                        <label>Nasıl daha iyi hale getirebiliriz?</label>
                        <Form.Item>
                            <TextArea rows={4} onChange={(e) => setComment(e.target.value)} value={comment}/>
                        </Form.Item>
                    </div>
                    <div className="categories">
                        <label>Kategori seçiniz</label>
                        <Radio.Group onChange={(e) => setCategoryId(e.target.value)} value={categoryId}>
                            <Radio value={1}>Öneri</Radio>
                            <Radio value={2}>Geliştirme</Radio>
                            <Radio value={3}>Hata</Radio>
                        </Radio.Group>
                    </div>
                </Form>
            </Modal>
        </>
    );
};

const mapStateToProps = (state) => {
    return {}
}

const mapActionsToProps = {
    newFeedback
}

export default connect(mapStateToProps, mapActionsToProps)(Feedback);