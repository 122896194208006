import React, {useState, useEffect} from 'react';
import './tests.scss';
import {connect} from "react-redux";
import {Icon} from '@iconify/react';
import {Badge, Button, Spin} from "antd";
import PageHead from "../../components/pageHead/pageHead";
import {Link} from "react-router-dom";
import {TESTS} from "../../commons/router";
import {fetchTests} from "../../redux/actions/testsAction";

const Tests = (props) => {
    const {fetchTests, tests, userTests, testQuestions, userTopics} = props;

    useEffect(() => {
        fetchTests();
    }, []);

    const testHtml = (test, key) => {
        const selectedTestId = test.id;
        const selectedTestQuestions = test.test_questions ? JSON.parse(test.test_questions) : [];
        const selectedUserTestAnswers = userTests[selectedTestId];

        const totalCount = selectedTestQuestions.length;

        let trueCount = 0;
        let falseCount = 0;

        if (selectedUserTestAnswers) {
            for (const questionId in selectedUserTestAnswers) {
                const answer = selectedUserTestAnswers[questionId];
                const selectedAnswer = answer.answer.toLowerCase();

                const selectedQuestionAnswers = JSON.parse(testQuestions[questionId].answers);
                const selectedQuestionCorrectAnswer = selectedQuestionAnswers.correct_answer.toLowerCase();

                if (selectedAnswer === selectedQuestionCorrectAnswer) {
                    trueCount++;
                } else {
                    falseCount++;
                }
            }
        }

        const selectedContent = userTopics[test.topic_id];
        const isCompleted = selectedContent && selectedContent.complete_date;

        if (isCompleted) {
            return (
                <Link to={`${TESTS}/${test.id}`} className="test active" key={key}>
                    <div className="number">

                        {
                            totalCount === trueCount ?
                                <Icon icon="mdi:success-bold" className="success"/>
                                :
                                <Icon icon="mdi:close-thick" className="fail"/>
                        }
                    </div>
                    <div className="name">{test.name}</div>
                    <div className="result">
                        {/*<div className="total">*/}
                        {/*    <Badge count={`${totalCount} Soru`} style={{backgroundColor: '#1677ff'}}/>*/}
                        {/*</div>*/}
                        <div className="true">
                            <Badge count={`${trueCount} Doğru`} style={{backgroundColor: '#52c41a'}}/>
                        </div>
                        <div className="false">
                            <Badge count={`${falseCount} Yanlış`}/>
                        </div>
                    </div>
                </Link>
            );
        } else {
            return (
                <Link to={""} className="test" key={key}>
                    <div className="number"><Icon icon="material-symbols:lock-sharp"/></div>
                    <div className="name">{test.name}</div>
                    <div className="result">Önce konuyu tamamlayın</div>
                </Link>
            );
        }

    }

    return (
        <>
            {
                Object.keys(tests).length > 0
                    ?
                    <>
                        <div className="tests">
                            <PageHead data={{
                                "img": "/img/test.png",
                                "title": "Testler",
                                // "subTitle": `<span>Toplam ${tests.length}</span> Test Sayısı`,
                                "desc": "Testler bölümümüzde, İngilizce dil becerilerinizi değerlendirebileceğiniz ve kendinizi sınayabileceğiniz çeşitli testler sunuyoruz."
                            }}/>
                            <div className="content-wrapper">
                                <div className="content">
                                    {tests.map((test, key) => testHtml(test, key))}
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <Spin className="wt-spin"/>

            }
        </>
    )
        ;
};

const mapStateToProps = (state) => {
    const testReducer = state.testsReducer;
    return {
        tests: testReducer.tests,
        userTests: testReducer.userTests,
        testQuestions: testReducer.testQuestions,
        userTopics: state.topicsReducer.userTopics,
    }
}

const mapActionsToProps = {
    fetchTests
}

export default connect(mapStateToProps, mapActionsToProps)(Tests);