import React, {useEffect, useState} from 'react';
import './patternsLearn.scss';
import {fetchPatterns, updateUserPatternsPartDate} from "../../redux/actions/patternsAction";
import {connect} from "react-redux";
import {Modal} from "antd";
import {PATTERNS} from "../../commons/router";
import {useParams, useNavigate} from 'react-router-dom';
import {ExclamationCircleFilled} from "@ant-design/icons";
import {Icon} from "@iconify/react/dist/iconify";
import {isMobileApp, speak} from "../../commons/common";

const PatternsLearn = (props) => {
    const {fetchPatterns, activeCourse, groupedPatterns, updateUserPatternsPartDate, userLevel} = props;
    const navigate = useNavigate();
    const params = useParams();
    const queryLevel = params.level;
    const [selectedPatterns, setSelectedPatterns] = useState();

    useEffect(() => {
        document.title = `${queryLevel}.Seviye kalıplar`;

        if (groupedPatterns.length <= 0) {
            fetchPatterns()
        }

        updateUserPatternsPartDate(activeCourse.level_id, params.level);
    }, []);

    useEffect(() => {
        const selectedLevel = queryLevel - 1;
        if (selectedLevel >= 0 && groupedPatterns.length > 0) {
            if (groupedPatterns[selectedLevel]) {
                const selectedPatterns = groupedPatterns[selectedLevel];
                setSelectedPatterns(selectedPatterns);
            }
        }
    }, [groupedPatterns]);

    return (
        <div className="patterns-learn">
            <div className="content-wrapper">
                <div className="content">
                    {
                        selectedPatterns && selectedPatterns.map(item => (
                            <div className="pattern" key={item.id}>
                                <div className="top">
                                    <p className="title">{item.pattern}</p>
                                    <p className="mean">{item.mean}</p>
                                </div>
                                {
                                    !isMobileApp() &&
                                    <div className="bottom">
                                        <div className="sound" onClick={() => speak(item.pattern)}>
                                            <Icon icon="heroicons:speaker-wave"/>
                                        </div>
                                    </div>
                                }
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>

    );
};

const mapStateToProps = (state) => {
    return {
        activeCourse: state.userCoursesReducer.activeCourse,
        groupedPatterns: state.patternsReducer.groupedPatterns,
        patterns: state.patternsReducer.patterns,
        userLevel: state.levelsReducer.userLevel,
    }
}

const mapActionsToProps = {
    fetchPatterns,
    updateUserPatternsPartDate
}

export default connect(mapStateToProps, mapActionsToProps)(PatternsLearn);