import React from 'react';
import {Button, Progress} from "antd";
import './community.scss';

const Community = () => {
    return (
        <div className="community">
            <div className="content-wrapper">
                <div className="content">
                    <div className="desc">
                        <h2>İngilizce Uygulama Topluluğumuza Katılın</h2>
                        <p>Merhaba! İngilizce öğrenmeye ve kendinizi geliştirmeye ilgi duyan herkesi topluluğumuza davet ediyoruz. Uygulamamız, dil becerilerinizi geliştirmek, yeni insanlarla bağlantı kurmak ve bilgi paylaşımında bulunmak için ideal bir ortam sunmaktadır.</p>
                        <Button type="primary" size="large" href="https://discord.gg/q2frTEJGMS" target="_blank">Hemen Katıl</Button>
                    </div>
                    <div className="img-wrapper"><img src="/img/community.png"/></div>
                </div>
            </div>
        </div>
    );
};

export default Community;
