import {SET_USER, SET_TOUR_STATUS} from "../actions/userAction";
import {SET_API_TOKEN} from "../actions/authAction";

const initalState = {
    user: "",
    apiToken: "EfM9GKXBbFsnC5YqL2wx6CIW1RSRSGg3OpAtA0Xu"
}

const userReducer = (state = initalState, action) => {
    switch (action.type) {
        case SET_USER:
            return {
                ...state,
                user: action.value
            }
        case SET_API_TOKEN:
            return {
                ...state,
                apiToken: action.value
            }
        case SET_TOUR_STATUS:
            return {
                ...state,
                user: {
                    ...state.user,
                    tour_status: action.value
                }
            }
        default:
            return state
    }
}
export default userReducer
