import axios from "axios";
import {API_URL, PATTERNS_GROUP_SIZE} from "../../commons/constants";

export const SET_PATTERNS = "SET_PATTERNS";
export const SET_GROUPED_PATTERNS = "SET_GROUPED_PATTERNS";
export const UPDATE_PATTERNS = "UPDATE_PATTERNS";
export const UPDATE_USER_PATTERNS = "UPDATE_USER_PATTERNS";

export const fetchPatterns = () => {
    return async (dispatch, getState) => {
        let patterns = getState().patternsReducer.patterns;
        if (Object.keys(patterns).length <= 0) {
            patterns = await axios.get(API_URL + '/daily-patterns', {
                headers: {Authorization: `Bearer ${getState().userReducer.apiToken}`}
            });
            patterns = patterns.data;

            const patternsWithKeys = {};
            const size = PATTERNS_GROUP_SIZE;

            const groupedData = patterns.reduce((acc, cur, i) => {
                if (i % size === 0) {
                    acc.push([]);
                }
                const lastGroup = acc[acc.length - 1];
                lastGroup.push(cur);

                patternsWithKeys[cur.id] = cur;
                return acc;
            }, []);

            dispatch(setPatterns(patterns))
            dispatch(setGroupedPatterns(groupedData))
        }
    }
}

export const setPatterns = (patterns) => {
    return {
        type: SET_PATTERNS,
        value: patterns
    }
}

export const setGroupedPatterns = (patterns) => {
    return {
        type: SET_GROUPED_PATTERNS,
        value: patterns
    }
}

export const markPattern = (vocabularyId, status) => {
    return async (dispatch, getState) => {
        const patterns = getState().userCoursesReducer.activeCourse.patterns;

        // if (status === VOCABULARY_OKAY) {
        //     if (!patterns[VOCABULARY_OKAY].includes(vocabularyId)) {
        //         patterns[VOCABULARY_OKAY].push(vocabularyId);
        //     }
        //     if (patterns[VOCABULARY_NOT_OKAY].includes(vocabularyId)) {
        //         patterns[VOCABULARY_NOT_OKAY].splice(patterns[VOCABULARY_NOT_OKAY].indexOf(vocabularyId), 1);
        //     }
        // }
        // else if (status === VOCABULARY_NOT_OKAY) {
        //     if (!patterns[VOCABULARY_NOT_OKAY].includes(vocabularyId)) {
        //         patterns[VOCABULARY_NOT_OKAY].push(vocabularyId);
        //     }
        //     if (patterns[VOCABULARY_OKAY].includes(vocabularyId)) {
        //         patterns[VOCABULARY_OKAY].splice(patterns[VOCABULARY_OKAY].indexOf(vocabularyId), 1);
        //     }
        // }

        dispatch(updatePatterns(patterns));


        await axios.post(API_URL + '/mark-vocabulary', {id: vocabularyId, status: status}, {
            headers: {Authorization: `Bearer ${getState().userReducer.apiToken}`}
        });
    }
}


export const updatePatterns = (patterns) => {
    return {
        type: UPDATE_PATTERNS,
        value: patterns
    }
}

export const updateUserPatternsPartDate = (levelId, part) => {
    return async (dispatch, getState) => {
        let response = await axios.post(API_URL + '/update-daily-pattern-part-date', {level_id: levelId, part: part}, {
            headers: {Authorization: `Bearer ${getState().userReducer.apiToken}`}
        });

        dispatch(updateUserPatterns(response.data.userPatterns))
    }
}


export const setUserPatterns = (userPatterns) => {
    return async (dispatch, getState) => {
        userPatterns = userPatterns ? JSON.parse(userPatterns) : {};

        dispatch(updateUserPatterns(userPatterns))
    }
}

export const updateUserPatterns = (userPatterns) => {
    return {
        type: UPDATE_USER_PATTERNS,
        value: userPatterns
    }
}
