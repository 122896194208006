import {SET_PATTERNS, SET_GROUPED_PATTERNS, UPDATE_USER_PATTERNS} from "../actions/patternsAction";

const initalState = {
    userPatterns: {},
    patterns: [],
    groupedPatterns: [],
}

const patternsReducer = (state = initalState, action) => {
    switch (action.type) {
        case SET_PATTERNS:
            return {
                ...state,
                patterns: action.value
            }
        case SET_GROUPED_PATTERNS:
            return {
                ...state,
                groupedPatterns: action.value
            }
        case UPDATE_USER_PATTERNS:
            return {
                ...state,
                userPatterns: {
                    ...action.value
                }
            }
        default:
            return state
    }
}
export default patternsReducer
