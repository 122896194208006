import {SET_TESTS, UPDATE_USER_TESTS, SET_TEST_QUESTIONS} from "../actions/testsAction";

const initalState = {
    userTests: {},
    tests: [],
    testQuestions: [],
}

const testsReducer = (state = initalState, action) => {
    switch (action.type) {
        case SET_TESTS:
            return {
                ...state,
                tests: action.value
            }
        case SET_TEST_QUESTIONS:
            return {
                ...state,
                testQuestions: action.value
            }
        case UPDATE_USER_TESTS:
            return {
                ...state,
                userTests: {
                    ...action.value
                }
            }
        default:
            return state
    }
}
export default testsReducer
