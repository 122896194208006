export const DASHBOARD = "/";
export const LEVELS = "/levels";
export const TOPICS = "/topics";
export const VOCABULARIES = "/vocabularies";
export const PATTERNS = "/patterns";
export const TESTS = "/tests";
export const ACCOUNT_SETTINGS = "/account-settings";
export const STATISTICS = "/statistics";
export const OTHER_MENUS = "/other-menus";
export const COMMUNITY = "/community";
export const FEEDBACK = "?feedback=1";

export const PAGE_INFORMATION = {
    [DASHBOARD]: {
        title: "Ana Sayfa"
    },
    [LEVELS]: {
        title: "Kurslar"
    },
    [TOPICS]: {
        title: "Konular"
    },
    [VOCABULARIES]: {
        title: "Kelime Listesi"
    },
    [PATTERNS]: {
        title: "Kalıplaşmış İfadeler"
    },
    [TESTS]: {
        title: "Testler"
    },
    [ACCOUNT_SETTINGS]: {
        title: "Hesap Ayarları"
    },
    [STATISTICS]: {
        title: "İstatistikler"
    },
    [OTHER_MENUS]: {
        title: "Diğer Menüler"
    },
    [COMMUNITY]: {
        title: "Topluluk"
    }
};

export const BACK_BUTTON_HIDDEN_ROUTES = [DASHBOARD, LEVELS, STATISTICS];