import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from "react-redux";
import configureStore from './redux/store';
import moment from 'moment-timezone';
import 'moment/locale/tr';
moment.tz.setDefault('Europe/Istanbul');

const store = configureStore();
// ReactDOM.render( <Provider store={store}><App /></Provider>, document.getElementById("app-inner-pages"));
//


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}><App/></Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();



