import {setCookie, deleteCookie} from "../../commons/helpers";
import axios from "axios";
import {API_URL} from "../../commons/constants";
import {setUser} from "./userAction";


export const SET_API_TOKEN = "SET_API_TOKEN";
export const API_TOKEN = "API_TOKEN";

export const loginWithForm = (email, password) => {
    return async (dispatch, getState) => {
        let response = await axios.post(API_URL + '/login', {email, password});
        response = response.data;
        if (response.data) {
            const {token, user} = response.data;
            dispatch(setUser(user));
            dispatch(setApiToken(token));
        }

        return response;
    }
}

export const registerWithForm = ({name, email, password}) => {
    return async (dispatch, getState) => {
        let response = await axios.post(API_URL + '/register', {"name": name, "email": email, "password": password, "password_confirmation": password});
        response = response.data;
        if (response.status) {
            dispatch(setUser(response.user));
            dispatch(setApiToken(response.token));
        }

        return response;
    }
}

export const setApiToken = (token) => {
    return {
        type: SET_API_TOKEN,
        value: token
    }
}


