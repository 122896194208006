import {SET_TOPICS, SET_TOPIC_CONTENT, UPDATE_USER_TOPICS} from "../actions/topicsActions";

const initalState = {
    userTopics: {},
    topics: {},
    topicsContent: {}
}

const topicsReducer = (state = initalState, action) => {
    switch (action.type) {
        case SET_TOPICS:
            return {
                ...state,
                topics: action.value
            }
        case SET_TOPIC_CONTENT:
            return {
                ...state,
                topicsContent: action.value
            }
        case UPDATE_USER_TOPICS:
            return {
                ...state,
                userTopics: {
                    ...action.value
                }
            }
        default:
            return state
    }
}
export default topicsReducer
