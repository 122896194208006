import {useRef, useState, useEffect} from 'react';
import './dashboard.scss';
import {connect} from "react-redux";
import Statistics from "../../components/dashboard/statistics/statistics";
import Education from "../../components/dashboard/education/education";
import DailyGuide from "../../components/dashboard/dailyGoals/dailyGuide";
import Profile from "../../components/dashboard/profile/profile";
import Community from "../../components/dashboard/community/community";
import StartLearn from "../../components/dashboard/startLearn/startLearn";
import {isMobile, isBrowser} from 'react-device-detect';
import {Tour} from 'antd';
import {useDispatch} from 'react-redux';
import {setTourStatus} from "../../redux/actions/userAction";
import {Icon} from '@iconify/react';
import {Link} from "react-router-dom";
import {PATTERNS, TESTS, TOPICS, VOCABULARIES} from "../../commons/router";


const Dashboard = (props) => {
    const {activeCourse, user, userLevel} = props;
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const ref4 = useRef(null);
    const ref5 = useRef(null);
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!user.tour_status) {
            setOpen(true);
        }
    }, []);

    useEffect(() => {
        if (!open) {
            dispatch(setTourStatus(true));
        }
    }, [open]);


    const steps = [
        {
            title: 'Günlük Öğrenim',
            description: 'İngilizce becerilerinizi geliştirmek için düzenli olarak zaman ayırmanızı ve her gün küçük adımlarla ilerlemenizi sağlayan bir yaklaşımdır.',
            target: () => ref1.current,
        },
        {
            title: 'Konu Anlatımı',
            description: 'Uygulamamızda konu anlatımları, çeşitli dilbilgisi kuralları, dil becerileri iletişim ipuçları gibi çeşitli konuları kapsar.',
            target: () => ref2.current,
        },
        {
            title: 'Kelimeler',
            description: 'Kelime kartlarıyla yeni kelimeler öğrenme özelliğimizle, İngilizce kelime dağarcığınızı hızlı ve etkili bir şekilde genişletebilirsiniz.\n',
            target: () => ref3.current,
        },
        {
            title: 'Testler',
            description: 'İngilizce dil becerilerinizi değerlendirebileceğiniz ve kendinizi sınayabileceğiniz çeşitli testler sunuyoruz.',
            target: () => ref4.current,
        },
        {
            title: 'Kalıplaşmış İfadeler',
            description: 'İngilizce dilinde sıkça kullanılan ve özel anlamları olan ifadeleri öğrenebilirsiniz.',
            target: () => ref5.current,
        },
    ];

    return (
        <div className="dashboard">
            <Tour open={open} onClose={() => setOpen(false)} steps={steps}/>
            {
                activeCourse
                    ?
                    <>
                        {
                            isBrowser &&
                            <>
                                <div className="container">
                                    <div className="left">
                                        <Profile/>
                                        <DailyGuide innerRef={ref1}/>
                                    </div>
                                    <div className="right">
                                        <Education innerRef={{ref2, ref3, ref4, ref5}}/>
                                    </div>
                                    <div className="bottom">
                                        <Statistics/>
                                    </div>
                                </div>
                                <div className="container-fluid">
                                    <Community/>
                                </div>
                            </>
                        }

                        {
                            isMobile &&
                            <>
                                <div className="container-v2">
                                    <div className="top">
                                        <DailyGuide innerRef={ref1}/>
                                    </div>

                                    <div className="bottom">
                                        <div className="main-education">
                                            <div className="title">Eğitimler</div>
                                            <div className="content">
                                                <Link to={`${TOPICS}/${userLevel.slug}`} className="item">
                                                    <div className="icon-box">
                                                        <img src="/img/mobile/grammer.png"/>
                                                    </div>
                                                    <div className="text">
                                                        <h4>Konu Anlatımı</h4>
                                                        <p>Tüm konuları bulabilir detaylı açıklamalarla öğrenebilirsiniz.</p>
                                                    </div>
                                                    <div className="custom-button">
                                                        <Icon icon="formkit:right"/>
                                                    </div>
                                                </Link>
                                                <Link to={VOCABULARIES} className="item">
                                                    <div className="icon-box">
                                                        <img src="/img/mobile/words.png"/>
                                                    </div>
                                                    <div className="text">
                                                        <h4>Kelime Öğren</h4>
                                                        <p>Yeni kelimeleri hızlı ve etkili bir şekilde öğrenmenizi sağlar</p>
                                                    </div>
                                                    <div className="custom-button">
                                                        <Icon icon="formkit:right"/>
                                                    </div>
                                                </Link>
                                                <Link to={TESTS} className="item">
                                                    <div className="icon-box">
                                                        <img src="/img/mobile/tests.png"/>
                                                    </div>
                                                    <div className="text">
                                                        <h4>Testler</h4>
                                                        <p>Pratik yapmak için tüm konularda testler bulabilirsiniz.</p>
                                                    </div>
                                                    <div className="custom-button">
                                                        <Icon icon="formkit:right"/>
                                                    </div>
                                                </Link>
                                                <Link to={PATTERNS} className="item">
                                                    <div className="icon-box">
                                                        <img src="/img/mobile/daily.png"/>
                                                    </div>
                                                    <div className="text">
                                                        <h4>Kalıplaşmış ifadeler</h4>
                                                        <p>Bilinmesi gereken İngilizce kalıplaşmış ifadeler.</p>
                                                    </div>
                                                    <div className="custom-button">
                                                        <Icon icon="formkit:right"/>
                                                    </div>
                                                </Link>

                                            </div>
                                        </div>
                                    </div>
                                </div>


                                {/*<div className="container" style={open ? {height: "50vh", overflowY: 'scroll'} : {}}>*/}
                                {/*    <div className="left">*/}
                                {/*        <DailyGuide innerRef={ref1}/>*/}
                                {/*    </div>*/}
                                {/*    <div className="right">*/}
                                {/*        <Education innerRef={{ref2, ref3, ref4, ref5}}/>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </>
                        }
                    </>
                    :
                    <StartLearn/>
            }

        </div>
    );
};


const mapStateToProps = (state) => {
    return {
        activeCourse: state.userCoursesReducer.activeCourse,
        user: state.userReducer.user,
        userLevel: state.levelsReducer.userLevel,
    }
}
const mapActionsToProps = {}


export default connect(mapStateToProps, mapActionsToProps)(Dashboard);
