import React from 'react';
import {Button, Progress} from "antd";
import './education.scss';
import {Link} from "react-router-dom";
import {PATTERNS, TESTS, TOPICS, VOCABULARIES} from "../../../commons/router";
import {Icon} from "@iconify/react/dist/iconify";
import {connect} from "react-redux";
import {isMobile, isBrowser} from "react-device-detect";

const Education = (props) => {
    const {userLevel, innerRef} = props;
    return (
        <div className="education">
            <div className="pro-banner" ref={innerRef?.ref5}>
                <div className="left">
                    <p className="title">Kalıplaşmış ifadeler</p>
                    <p className="desc">Bilinmesi gereken İngilizce kalıplaşmış ifadeler</p>
                </div>
                <img src="/img/video-lesson.png"/>
                <div className="right">
                    <Link to={PATTERNS}><Button type="primary" size="large">Öğren</Button></Link>
                </div>
            </div>
            <div className="get-started">
                <div className="content-wrapper">
                    <div className="title">
                        <h1>ÖĞRENMEYE HIZLICA BAŞLA!</h1>
                        <h3>İngilizcenizi geliştirmek için başlamaya hazırsınız</h3>
                    </div>

                    <div className="content">
                        <div className="left" ref={innerRef?.ref3}>
                            {
                                isMobile && <img src="/img/words.png"/>
                            }
                            <h2>Kelime Öğren</h2>
                            <p>Yeni kelimeleri hızlı ve etkili bir şekilde öğrenmenizi sağlar</p>
                            {
                                isBrowser &&
                                <div className="icon">
                                    <Icon icon="mdi:dictionary"/>
                                </div>
                            }

                            <Link to={VOCABULARIES}><Button type="primary" size="large">Kelimeleri öğren</Button></Link>

                        </div>
                        <div className="middle" ref={innerRef?.ref2}>
                            {
                                isMobile && <img src="/img/grammer.png"/>
                            }
                            <h2>Konu Anlatımı</h2>
                            <p>İhtiyaç duyduğunuz konuyu hızlıca bulabilir ve detaylı açıklamalarla öğrenebilirsiniz.</p>
                            {
                                isBrowser &&
                                <>
                                    <div className="icon">
                                        <Icon icon="ph:book-open-thin"/>
                                    </div>
                                    <img src="/img/sky.png"/>
                                </>
                            }


                            <Link to={`${TOPICS}/${userLevel.slug}`}><Button type="primary" size="large">Hemen başla</Button></Link>
                        </div>
                        <div className="right" ref={innerRef?.ref4}>
                            {
                                isMobile && <img src="/img/test.png"/>
                            }
                            <h2>Testler</h2>
                            <p>İngilizce dil becerilerinizi ölçmek ve pratik yapmak için tüm konularda testler sunar.</p>
                            {
                                isBrowser &&
                                <div className="icon">
                                    <Icon icon="icon-park-solid:list-success"/>
                                </div>
                            }

                            <Link to={TESTS}><Button type="primary" size="large">Çözmeye başla</Button></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userLevel: state.levelsReducer.userLevel,
    }
}
const mapActionsToProps = {}


export default connect(mapStateToProps, mapActionsToProps)(Education);
