import React, {useEffect} from 'react';
import {Button, Progress} from "antd";
import './startLearn.scss';
import {Icon} from "@iconify/react/dist/iconify";
import {LEVELS} from "../../../commons/router";
import {Link} from "react-router-dom";
import PageHead from "../../pageHead/pageHead";


const StartLearn = () => {
    useEffect(() => {
        document.body.classList.add("start-learn");

        return () => {
            document.body.classList.remove("start-learn");
        };
    }, []);

    return (
        <div className="start-learn">
            <PageHead data={{
                "title": "Eğitim seçerek başla.",
                "desc": "Eğitim yolculuğunuza ücretsiz olarak adım atmak için doğru yerdesiniz. Eğitim seçerek başlayın ve hedeflerinize ulaşmak için ilk adımı atın."
            }}/>
            <div className="content-wrapper">
                <div className="content">
                    <div className="left">
                        <img src="/img/new-register.png" alt=""/>
                    </div>
                    <div className="right">
                        <ul>
                            <li><Icon icon="emojione-v1:left-check-mark"/> <b>Konu Anlatımlarına</b> çalış</li>
                            <li><Icon icon="emojione-v1:left-check-mark"/> <b>Kelime Öğrenme</b> yolculuğuna başla</li>
                            <li><Icon icon="emojione-v1:left-check-mark"/> <b>Testleri</b> çözerek pratik yapmaya başla</li>
                            <li><Icon icon="emojione-v1:left-check-mark"/> <b>Kalıplaşmış ifadeleri</b> öğrenerek gelişimini hızlandır</li>
                            <li><Icon icon="emojione-v1:left-check-mark"/> <b>İlerlemen</b> hakkında bilgi sahibi ol</li>
                            <li><Icon icon="emojione-v1:left-check-mark"/> <b>Tamamen ücretsiz</b> İngilizce gelişimini tamamla</li>
                        </ul>
                        <Link to={LEVELS}><Button type="primary">Kurs Seç</Button></Link>
                    </div>
                </div>
                <p className="info">İngilizce gelişiminizi tamamlamak için harika bir fırsatla karşınızdayız. Tamamen ücretsiz olarak İngilizce becerilerinizi ilerletebilir, dil bilginizi geliştirebilir ve kendinizi güvenle ifade edebilirsiniz. Size sunduğumuz <b>ücretsiz</b> İngilizce eğitimleri sayesinde okuma, yazma, dinleme ve konuşma becerilerinizi güçlendirebilirsiniz.</p>
            </div>
        </div>
    );
};

export default StartLearn;
