import {PATTERNS, TESTS, TOPICS, VOCABULARIES} from "./router";

let API_PUBLIC_STORAGE_URL;
let API_BASE_URL;
let API_URL;
let COOKIE_DOMAIN;
let OUTER_PAGE_URL;
let MOBILE_LOGOUT_PAGE_URL;
export const IS_MOBILE_APP = "is_mobile_app";

if (process.env.NODE_ENV === "development") {
    API_PUBLIC_STORAGE_URL = "http://127.0.0.1:8000/storage/";
    API_BASE_URL = "http://127.0.0.1:8000/";
    API_URL = "http://127.0.0.1:8000/api";
    COOKIE_DOMAIN = "";
    OUTER_PAGE_URL = "http://localhost:3000/";
    MOBILE_LOGOUT_PAGE_URL = `http://localhost:3000/auth?${IS_MOBILE_APP}=1&logout=1`;
} else {
    API_PUBLIC_STORAGE_URL = "https://api.safelyenglish.com.tr/storage/";
    API_BASE_URL = "https://api.safelyenglish.com.tr/";
    API_URL = "https://api.safelyenglish.com.tr/api";
    COOKIE_DOMAIN = ".safelyenglish.com.tr";
    OUTER_PAGE_URL = "https://www.safelyenglish.com.tr/";
    MOBILE_LOGOUT_PAGE_URL = `https://www.safelyenglish.com.tr/auth?${IS_MOBILE_APP}=1&logout=1`;
}


export {API_PUBLIC_STORAGE_URL, API_BASE_URL, API_URL, COOKIE_DOMAIN, OUTER_PAGE_URL, MOBILE_LOGOUT_PAGE_URL};


export const VOCABULARY_REMAINING = "remaining";
export const VOCABULARY_OKAY = "okay";
export const VOCABULARY_NOT_OKAY = "not_okay";


export const VOCABULARIES_TYPES = {
    [VOCABULARY_REMAINING]: {
        "title": "kalan kelimeler"
    },
    [VOCABULARY_OKAY]: {
        "title": "öğrendiğim kelimeler"
    },
    [VOCABULARY_NOT_OKAY]: {
        "title": "zorlandığım kelimeler"
    }
}

export const VOCABULARIES_GROUP_SIZE = 20;

export const PATTERNS_GROUP_SIZE = 8;


export const DAILY_GUIDES = [
    {
        "key": "topics",
        "desc": "1-Konu anlatımlarına çalış",
        "url": TOPICS
    },
    {
        "key": "vocabularies",
        "desc": "2-Kelimeler Öğren",
        "url": VOCABULARIES
    },
    {
        "key": "tests",
        "desc": "3-Testleri Çöz",
        "url": TESTS
    },
    {
        "key": "dailyPatterns",
        "desc": "4-Kalıplaşmış ifadelere çalış",
        "url": PATTERNS
    }
]