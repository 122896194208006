import React from 'react';
import {Avatar, Progress} from "antd";
import './profile.scss';
import {Link} from "react-router-dom";
import {ACCOUNT_SETTINGS} from "../../../commons/router";
import {updatePassword, updateProfile} from "../../../redux/actions/userAction";
import {connect} from "react-redux";
import moment from "moment";

const Profile = (props) => {
    const {user} = props;
    const fullName = user.name + " " + (user.surname ? user.surname : "");
    const userCreatedAt = moment(user.created_at).format('DD MMMM YYYY');

    return (
        <div className="profile">
            <div className="content">
                <div className="avatar-container">
                    <div className="info">
                        <div className="name">{fullName ? fullName : "İsimsiz"}</div>
                    </div>
                    <Avatar className="avatar" size={38}>E</Avatar>
                    <Link to={ACCOUNT_SETTINGS} className="profile-button">Hesap Ayarları</Link>
                    {/*<div className="desc">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>*/}
                    <div className="member-since">Üyelik Tarihi: <b>{userCreatedAt}</b></div>
                </div>
            </div>
        </div>
    );
};


const mapStateToProps = (state) => {
    return {
        user: state.userReducer.user,
    }
}

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(Profile);