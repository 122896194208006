import React, {useEffect} from 'react';
import {Button, Card, Collapse, Spin} from "antd";
import './topics.scss';
import {Icon} from '@iconify/react';
import {Link, useParams} from "react-router-dom";
import {TOPICS} from "../../commons/router";
import {fetchTopics} from "../../redux/actions/topicsActions";
import {connect} from "react-redux";
import {API_PUBLIC_STORAGE_URL} from "../../commons/constants";
import PageHead from "../../components/pageHead/pageHead";

const {Panel} = Collapse;
const Topics = (props) => {
    const params = useParams();
    const {fetchTopics, topics, userTopics, userLevel} = props;

    useEffect(() => {
        if (Object.keys(topics).length <= 0) {
            fetchTopics();
        }
    }, []);


    //topics listeme yerinde sadece bir tane öğren butonu çıksın diye yaptık
    if (userTopics && Object.keys(topics).length > 0) {
        for (const index in topics) {
            const topic = topics[index];
            const selectedCourseContent = userTopics[topic.id];
            if (!selectedCourseContent || (selectedCourseContent && !selectedCourseContent.complete_date)) {
                topics[index]["selected"] = true;
                break;
            }
        }
    }


    const topicHtml = (topic) => {

        const selectedContent = userTopics[topic.id];
        const isCompleted = selectedContent && selectedContent.complete_date;
        if (isCompleted) {
            return (
                <Link to={`${TOPICS}/${userLevel.slug}/lesson/${topic.slug}`} key={topic.id}>
                    <Card className="topic">
                        <div className="top">
                            <div className="icon">
                                <Icon icon="carbon:document"/>
                            </div>
                            <div className="texts">
                                <div className="type">{topic.type.name}</div>
                                <div className="title">{topic.title}</div>
                                <div className="xp">XP +10</div>
                            </div>
                            <div className="status">
                                <Icon icon="material-symbols:check-small"/>
                            </div>
                        </div>
                    </Card>
                </Link>
            )
        }
        else {
            if (topic.selected) {
                return (<Card className={`topic`} key={topic.id}>
                    <div className="top">
                        <div className="icon">
                            <Icon icon="carbon:document"/>
                        </div>
                        <div className="texts">
                            <div className="type">{topic.type.name}</div>
                            <div className="title">{topic.title}</div>
                            <div className="desc">{topic.content}</div>
                            <div className="xp">XP +10</div>
                        </div>
                    </div>

                    <Link to={`${TOPICS}/${userLevel.slug}/lesson/${topic.slug}`}>
                        <Button type="primary" className="button" block={true} size={"large"}>
                            Öğren
                        </Button>
                    </Link>
                </Card>);
            }
            else {
                return (<Card className={`topic locked`} key={topic.id}>
                    <div className="top">
                        <div className="icon">
                            <Icon icon="carbon:document"/>
                        </div>
                        <div className="texts">
                            <div className="type">{topic.type.name}</div>
                            <div className="title">{topic.title}</div>
                            <div className="desc">{topic.content}</div>
                            <div className="xp">XP +10</div>
                        </div>
                        <div className="status">
                            <Icon icon="material-symbols:lock-sharp"/>
                        </div>
                    </div>
                </Card>);
            }
        }

    }

    return (
        <div className="topics custom-container">
            <div className="content-wrapper">
                {
                    (userLevel && Object.keys(topics).length > 0) ?
                        <>
                            <PageHead data={{
                                "img": "/img/grammer.png",
                                "title": "Konu Anlatımı",
                                "desc": "İngilizce dilinin farklı konuları hakkında kapsamlı bir bilgi kaynağı sunuyoruz."
                            }}/>

                            <div className="content">
                                <Collapse defaultActiveKey={['1']} bordered={false}>
                                    <Panel className="panel-container" header={<><Icon icon="bi:play-btn-fill"/>Hızlıca Başla</>} key="1">
                                        {
                                            Object.values(topics).map((topic, index) => topicHtml(topic))
                                        }
                                    </Panel>
                                </Collapse>
                            </div>
                        </>
                        : <Spin  className="wt-spin"/>
                }
            </div>
        </div>

    );
};

const mapStateToProps = (state) => {
    return {
        topics: state.topicsReducer.topics,
        userTopics: state.topicsReducer.userTopics,
        userLevel: state.levelsReducer.userLevel,
    }
}

const mapActionsToProps = {
    fetchTopics
}

export default connect(mapStateToProps, mapActionsToProps)(Topics);