import axios from "axios";
import {API_URL} from "../../commons/constants";
import {findLevelByLevelId} from "../../commons/helpers";

export const SET_LEVELS = "SET_LEVELS";
export const UPDATE_USER_LEVEL = "UPDATE_USER_LEVEL";

export const fetchLevels = () => {
    return async (dispatch, getState) => {
        const userReducer = getState().userReducer;
        let apiToken = userReducer.apiToken;
        let response = await axios.get(API_URL + '/levels', {
            headers: {Authorization: `Bearer ${apiToken}`}
        });
        dispatch(setLevels(response.data))
    }
}

export const setLevels = (levels) => {
    return {
        type: SET_LEVELS,
        value: levels
    }
}

export const setUserLevel = (levelId) => {
    return async (dispatch, getState) => {
        const level = findLevelByLevelId(getState().levelsReducer.levels, levelId)
        dispatch(updateUserLevel(level))
    }
}

export const updateUserLevel = (level) => {
    return {
        type: UPDATE_USER_LEVEL,
        value: level
    }
}
