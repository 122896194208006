import React, {useState} from 'react';
import {Button, Checkbox, Form, Input} from 'antd';
import './login.scss';
import {connect} from "react-redux";
import {loginWithForm} from "../../redux/actions/authAction";
import {Link} from "react-router-dom";

const Login = (props) => {
    const [error, setError] = useState(false);
    const onFinish = async ({email, password}) => {
        try {
            const response = await props.loginWithForm(email, password);
            setError("Giriş başarılı");
        } catch (e) {
            setError("Kullanıcı adı veya şifre yanlış");
        }
    };


    return (
        <div className="login-form">
            <h1>Login Page</h1>
            <Form
                name="basic"
                onFinish={onFinish}
                autoComplete="off"
            >
                <Form.Item
                    name="email"
                    rules={[
                        {
                            type: "email",
                            required: true,
                            message: 'Please input your email!',
                        },
                    ]}
                >
                    <Input placeholder="Email"/>
                </Form.Item>

                <Form.Item
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your password!',
                        },
                    ]}
                >
                    <Input.Password placeholder="Password"/>
                </Form.Item>
                {
                    error &&
                    <p>{error}</p>
                }
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
            <p>Hesabınız yoksa <Link to="/register">Kayıt Ol</Link></p>
        </div>
    );
};


const mapStateToProps = (state) => {
    return {
        user: state.userReducer.user,
    }
}

const mapActionsToProps = {
    loginWithForm
}

export default connect(mapStateToProps, mapActionsToProps)(Login);
