import {SET_VOCABULARIES, SET_GROUPED_VOCABULARIES, UPDATE_USER_VOCABULARIES} from "../actions/vocabulariesAction";

const initalState = {
    userVocabularies: {},
    vocabularies: [],
    groupedVocabularies: [],
}

const vocabulariesReducer = (state = initalState, action) => {
    switch (action.type) {
        case SET_VOCABULARIES:
            return {
                ...state,
                vocabularies: action.value
            }
        case SET_GROUPED_VOCABULARIES:
            return {
                ...state,
                groupedVocabularies: action.value
            }
        case UPDATE_USER_VOCABULARIES:
            return {
                ...state,
                userVocabularies: {
                    ...action.value
                }
            }
        default:
            return state
    }
}
export default vocabulariesReducer
