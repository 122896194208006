import React, {useState, useEffect} from 'react';
import {Card, Button, Modal,Spin} from 'antd';
import {ExclamationCircleFilled} from '@ant-design/icons';
import './levels.scss';
import {enrollCourse} from "../../redux/actions/userCourseAction";
import {connect} from "react-redux";
import {useNavigate} from 'react-router-dom'
import {DASHBOARD} from "../../commons/router";
import {API_PUBLIC_STORAGE_URL} from "../../commons/constants";
import PageHead from "../../components/pageHead/pageHead";

const Levels = (props) => {
    const navigate = useNavigate()
    const {levels, enrollCourse, activeCourse} = props;
    const selectLevel = (level) => {
        const confirmModal = Modal.confirm({
            title: level.name,
            icon: <ExclamationCircleFilled/>,
            content: 'Başlamak istiyor musunuz? Otomatik olarak eğitim sayfasına yönlendirileceksiniz.',
            maskClosable: true,
            onOk() {
                return new Promise(async (resolve, reject) => {
                    await enrollCourse(level);
                    confirmModal.destroy();
                    // navigate(DASHBOARD)
                    window.location.href=DASHBOARD;//sayfa yenilenmeden giderse istatistik sorunu oluyor daha sonrasında bakarsın
                    resolve(true)
                });
            }
        });
    }

    return (
        <div className="levels custom-container">
            <PageHead data={{
                "img": "/img/new-register.png",
                "title": "Kurslar",
                "desc": "Bu içerikler, kullanıcıların ihtiyaçlarına göre çeşitli konuları öğrenerek iletişim becerilerini geliştirmelerine yardımcı olmak için tasarlanmıştır."
            }}/>
            <div className="content-wrapper">
                {
                    levels.length > 0
                        ?
                        levels.map((level, index) => (
                            <div className="level" key={index}>
                                <div className="left">
                                    <div className="wrapper">
                                        <div className="logo">
                                            <img src={`${API_PUBLIC_STORAGE_URL}${level.photo}`}/>
                                        </div>
                                        <div className="info">
                                            <div className="top">
                                                <div className="title">{level.name}</div>
                                                <div className="desc">{level.description}</div>
                                            </div>
                                            <div className="bottom">

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="right">
                                    {
                                        (activeCourse && activeCourse.level_id === level.id) ?
                                            <Button type="primary" size="large" disabled>Devam Ediyor</Button>
                                            :
                                            <Button type="primary" size="large" onClick={() => selectLevel(level)}>Başla</Button>
                                    }
                                </div>
                            </div>
                        ))
                        :
                        <Spin  className="wt-spin"/>

                }
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        levels: state.levelsReducer.levels,
        activeCourse: state.userCoursesReducer.activeCourse,
        courses: state.userCoursesReducer.courses,
    }
}

const mapActionsToProps = {
    enrollCourse
}

export default connect(mapStateToProps, mapActionsToProps)(Levels);