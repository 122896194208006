import {connect, useDispatch} from "react-redux";
import {useEffect, useState} from "react";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Login from "./pages/login/login";
import Register from "./pages/register/register";
import Levels from "./pages/levels/levels";
import Topics from "./pages/topics/topics";
import Vocabularies from "./pages/vocabularies/vocabularies";
import Patterns from "./pages/patterns/patterns";
import PatternsLearn from "./pages/patternsLearn/patternsLearn";
import VocabulariesLearn from "./pages/vocabulariesLearn/vocabulariesLearn";
import Lesson from "./pages/lesson/lesson";
import Tests from "./pages/tests/tests";
import TestsMake from "./pages/testsMake/testsMake";
import Statistics from "./pages/statistics/statistics";
import Dashboard from "./pages/dashboard/dashboard";
import AccountSettings from "./pages/accountSettings/accountSettings";
import OthersMenus from "./pages/otherMenus/otherMenus";
import Community from "./pages/community/community";
import {fetchUser} from "./redux/actions/userAction";
import {fetchAllMaterialsCount, fetchCourses} from "./redux/actions/userCourseAction";
import {fetchLevels} from "./redux/actions/levelsAction";
import './App.scss';
import Content from "./components/content/content";
import Feedback from "./components/feedback/feedback";
import {Spin} from "antd";
import {isMobile} from 'react-device-detect';
import General from "./components/general/general";
import {SET_API_TOKEN} from "./redux/actions/authAction";

const App = (props) => {
    const {fetchUser, fetchLevels, fetchCourses, fetchAllMaterialsCount} = props;
    const [load, setLoad] = useState(false);
    const dispatch = useDispatch();
    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        window.scrollTo(0, scrollPosition); // Sayfa yüklendiğinde scrollu yeniden ayarla
    }, []);

    const handleScroll = () => {
        setScrollPosition(window.pageYOffset); // Scroll pozisyonunu kaydet
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        async function initApp() {
            dispatch({type: SET_API_TOKEN});
            // Başlatılacak 4 fonksiyonu Promise nesnelerine sarıyoruz


            const fetchUserPromise = fetchUser();
            const fetchLevelsPromise = fetchLevels();
            await Promise.all([
                fetchUserPromise,
                fetchLevelsPromise,
            ]);


            const fetchCoursesPromise = fetchCourses();
            const fetchAllMaterialsCountPromise = fetchAllMaterialsCount();

            // Tüm Promise'leri bekleyen bir Promise oluşturuyoruz
            await Promise.all([
                fetchCoursesPromise,
                fetchAllMaterialsCountPromise,
            ]);

            setLoad(true);
        }

        initApp();
    }, []);

    return (
        <>
            {
                load ?
                    <Router>
                        <main className={`main ${isMobile ? "main-mobile" : ""}`}>
                            <General/>
                            <Content>
                                <Routes>
                                    <Route path="/" element={<Dashboard/>}/>
                                    <Route path="/login" element={<Login/>}/>
                                    <Route path="/register" element={<Register/>}/>
                                    <Route path="/levels" element={<Levels/>}/>
                                    <Route path="/topics/:level" element={<Topics/>}/>
                                    <Route path="/topics/:level/:type?/:lesson?" element={<Lesson/>}/>
                                    <Route path="/vocabularies" element={<Vocabularies/>}/>
                                    <Route path="/vocabularies/:level/:type" element={<VocabulariesLearn/>}/>
                                    <Route path="/patterns" element={<Patterns/>}/>
                                    <Route path="/patterns/:level" element={<PatternsLearn/>}/>
                                    <Route path="/tests" element={<Tests/>}/>
                                    <Route path="/tests/:id" element={<TestsMake/>}/>
                                    <Route path="/account-settings" element={<AccountSettings/>}/>
                                    <Route path="/statistics" element={<Statistics/>}/>
                                    <Route path="/other-menus" element={<OthersMenus/>}/>
                                    <Route path="/community" element={<Community/>}/>
                                </Routes>
                                <Feedback/>
                            </Content>
                        </main>
                    </Router>
                    : <Spin className="wt-spin"/>
            }
        </>
    );
}

const mapStateToProps = (state) => {
    return {}
}

const mapActionsToProps = {
    fetchUser,
    fetchLevels,
    fetchCourses,
    fetchAllMaterialsCount,
}

export default connect(mapStateToProps, mapActionsToProps)(App);