import React, {useEffect} from 'react';
import './otherMenus.scss';
import Profile from "../../components/dashboard/profile/profile";
import {Link} from "react-router-dom";
import {DASHBOARD, ACCOUNT_SETTINGS, FEEDBACK, COMMUNITY} from "../../commons/router";
import {logout} from "../../commons/common";

const OtherMenus = () => {
    useEffect(() => {
        document.body.classList.add("others-menus");
        return () => {
            document.body.classList.remove("others-menus");
        };
    }, []);
    return (
        <div className="others-menus">
            <div className="content-wrapper">
                <Profile/>
                <div className="menus">
                    <ul>
                        <li><Link to={COMMUNITY}>Topluluk</Link></li>
                        <li><Link to={DASHBOARD}>Blog</Link></li>
                        <li><Link to={FEEDBACK}>Destek</Link></li>
                        <li><Link to={ACCOUNT_SETTINGS}>Hesap Ayarları</Link></li>
                        {/*<li><a href="https://www.safelyenglish.com/google">Hesap Ayarları</a></li>*/}
                        <li><Link to="#" onClick={() => logout()}>Çıkış</Link></li>
                    </ul>
                </div>
            </div>

        </div>
    );
};

export default OtherMenus;
