import axios from "axios";
import {API_URL} from "../../commons/constants";

export const newFeedback = ({point, comment, categoryId, categoryName, details}) => {
    return async (dispatch, getState) => {
        const userReducer = getState().userReducer;

        await axios.post(API_URL + '/new-feedback', {point, comment, categoryId, categoryName, details}, {
            headers: {Authorization: `Bearer ${getState().userReducer.apiToken}`}
        });
    }
}