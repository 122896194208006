import axios from "axios";
import {API_URL} from "../../commons/constants";
import update from "immutability-helper";

export const SET_TOPICS = "SET_TOPICS";
export const SET_TOPIC_CONTENT = "SET_TOPIC_CONTENT";
export const UPDATE_USER_TOPICS = "UPDATE_USER_TOPICS";

export const fetchTopics = () => {
    return async (dispatch, getState) => {
        let response = await axios.get(`${API_URL}/topics`, {
            headers: {Authorization: `Bearer ${getState().userReducer.apiToken}`}
        });

        dispatch(setTopics(response.data))
    }
}

export const setTopics = (topics) => {
    return {
        type: SET_TOPICS,
        value: topics
    }
}

export const setUserTopics = (topics) => {
    return async (dispatch, getState) => {
        topics = topics ? JSON.parse(topics) : {}
        dispatch(updateUserTopics(topics))
    }
}

export const updateUserTopics = (topics) => {
    return {
        type: UPDATE_USER_TOPICS,
        value: topics
    }
}

export const fetchTopicContent = (topicSlug) => {
    return async (dispatch, getState) => {
        const response = await axios.get(`${API_URL}/contents/${topicSlug}`, {
            headers: {Authorization: `Bearer ${getState().userReducer.apiToken}`}
        });
        const content = response.data || [];
        if (content.length > 0) {
            let contentFirstItem = content[0];
            let topicsContent = getState().topicsReducer.topicsContent;
            if (!topicsContent[contentFirstItem.topic_id]) {
                topicsContent = update(topicsContent, {[contentFirstItem.topic_id]: {$set: content}});
            }
            dispatch(setTopicContent(topicsContent))
        }
        return content;
    }
}

export const setTopicContent = (content) => {
    return {
        type: SET_TOPIC_CONTENT,
        value: content
    }
}
