import React, {useEffect, useState} from 'react';
import './vocabulariesLearn.scss';
import {fetchVocabularies, markVocabulary} from "../../redux/actions/vocabulariesAction";
import {connect} from "react-redux";
import {Button, Modal, Progress, Spin} from "antd";
import {VOCABULARIES} from "../../commons/router";
import {useParams, useNavigate} from 'react-router-dom';
import {VOCABULARIES_TYPES, VOCABULARY_OKAY, VOCABULARY_NOT_OKAY, VOCABULARY_REMAINING} from "../../commons/constants";
import {ExclamationCircleFilled} from "@ant-design/icons";
import {Icon} from '@iconify/react';
import {isMobileApp, speak} from "../../commons/common";

const VocabulariesLearn = (props) => {
    const {fetchVocabularies, groupedVocabularies, markVocabulary, userLevel} = props;
    const navigate = useNavigate();
    const params = useParams();
    const queryType = params.type;
    const queryLevel = params.level;

    const vocabularyType = VOCABULARIES_TYPES[queryType];
    const [progressBar, setProgressBar] = useState({});
    const [showMean, setShowMean] = useState(false);
    const [selectedVocabularies, setSelectedVocabularies] = useState();
    const [selectedWord, setSelectedWord] = useState();
    const [pageTitle, setPageTitle] = useState();

    useEffect(() => {
        const title = `${queryLevel}.Seviye ${vocabularyType.title}`;
        setPageTitle(title);
        document.title = title


        document.body.classList.add("vocabularies-learn");
        if (groupedVocabularies.length <= 0) {
            fetchVocabularies()
        }
        return () => {
            document.body.classList.remove("vocabularies-learn");
        };
    }, []);

    useEffect(() => {
        const selectedLevel = queryLevel - 1;
        if (selectedLevel >= 0 && groupedVocabularies.length > 0) {
            if (groupedVocabularies[selectedLevel]) {
                const selectedVocabularies = groupedVocabularies[selectedLevel][queryType];
                setSelectedVocabularies(selectedVocabularies);

                const maxPart = selectedVocabularies.length;
                const percentageIncrease = 100 / maxPart;
                setProgressBar({maxPart: selectedVocabularies.length, nowPart: 0, percentageIncrease: percentageIncrease})
            }
        }
    }, [groupedVocabularies]);


    useEffect(() => {
        if (progressBar.nowPart >= 0) {
            if (selectedVocabularies[progressBar.nowPart]) {
                setSelectedWord(selectedVocabularies[progressBar.nowPart])
            }
        }
    }, [progressBar.nowPart]);

    const nextWord = (status) => {
        setProgressBar({...progressBar, nowPart: progressBar.nowPart + 1})
        setShowMean(false);


        if (status) {
            markVocabulary(selectedWord.id, status);
        }
    }

    const closeVocabularies = () => {
        Modal.confirm({
            title: "Ayrılmak istediğine emin misin?",
            icon: <ExclamationCircleFilled/>,
            maskClosable: true,
            onOk() {
                navigate(`${VOCABULARIES}`)
            }
        });
    }


    return (
        <>
            {
                selectedWord ?
                    <>
                        {
                            progressBar.nowPart === progressBar.maxPart ?
                                (
                                    <div className="vocabularies-learn completed">
                                        <div className="top">
                                            <div className="progress-bar">
                                                <Progress percent={progressBar.nowPart * progressBar.percentageIncrease}/>
                                            </div>
                                        </div>
                                        <div className="middle">
                                            <div className="content">
                                                <img src="/img/completed.png"/>
                                                <h3>Yeni kelimeler öğrenildi</h3>
                                                <p>Yeni kelimeler öğrendin daha fazla kelime öğrenerek İngilizce eğitimini devam ettir</p>
                                            </div>
                                        </div>
                                        <div className="bottom">
                                            <div className="content">
                                                <Button type="primary" className="button" onClick={() => navigate(`${VOCABULARIES}`)}>
                                                    Kelime Öğrenmeye Devam Et
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                )
                                :
                                <div className={`vocabularies-learn ${showMean && 'active'}`}>
                                    <div className="content-wrapper">
                                        <div className="top">
                                            <div className="info"><Icon icon="ic:baseline-close" onClick={() => closeVocabularies()}/><span>{pageTitle}</span></div>
                                            <div className="progress-bar">
                                                <Progress percent={progressBar.nowPart * progressBar.percentageIncrease} steps={progressBar.maxPart} showInfo={false}/>
                                            </div>
                                        </div>
                                        <div className={`middle`}>
                                            <div className={`content ${showMean && 'active'}`}>
                                                <div className="word-wrapper">
                                                    <div className="word">{selectedWord.word}</div>
                                                    <hr/>
                                                    <div className={`mean hidden`}>{selectedWord.mean}</div>
                                                </div>

                                                {
                                                    isMobileApp() ?
                                                        <div className="sound" onClick={() => setShowMean(true)}>
                                                            <Icon icon="ic:outline-refresh"/>
                                                        </div>
                                                        :
                                                        <div className="sound" onClick={() => speak(selectedWord.word)}>
                                                            <Icon icon="heroicons:speaker-wave"/>
                                                        </div>
                                                }

                                            </div>
                                        </div>
                                        <div className="bottom">
                                            <div className={`content ${queryType}`}>
                                                {
                                                    showMean
                                                        ?
                                                        <>
                                                            <Button type="primary" className="button skip" onClick={() => nextWord("")}>Geç</Button>
                                                            {
                                                                (queryType === VOCABULARY_NOT_OKAY || queryType === VOCABULARY_REMAINING) &&
                                                                <Button type="primary" className="button okay" onClick={() => nextWord(VOCABULARY_OKAY)}>Anladım</Button>
                                                            }
                                                            {
                                                                (queryType === VOCABULARY_OKAY || queryType === VOCABULARY_REMAINING) &&
                                                                <Button type="primary" className="button notOkay" onClick={() => nextWord(VOCABULARY_NOT_OKAY)}>Zor Kelimelere Ekle</Button>
                                                            }
                                                        </>
                                                        :
                                                        <Button type="primary" className="button" onClick={() => setShowMean(true)}>Göster</Button>

                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }
                    </>
                    :
                    <Spin className="wt-spin"/>
            }
        </>

    );
};

const mapStateToProps = (state) => {
    return {
        activeCourse: state.userCoursesReducer.activeCourse,
        groupedVocabularies: state.vocabulariesReducer.groupedVocabularies,
        vocabularies: state.vocabulariesReducer.vocabularies,
        userLevel: state.levelsReducer.userLevel,
    }
}

const mapActionsToProps = {
    fetchVocabularies,
    markVocabulary
}

export default connect(mapStateToProps, mapActionsToProps)(VocabulariesLearn);