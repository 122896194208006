import React, {useEffect, useState} from 'react';
import './patterns.scss';
import {fetchPatterns} from "../../redux/actions/patternsAction";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {PATTERNS} from "../../commons/router";
import {PATTERNS_GROUP_SIZE} from "../../commons/constants";
import moment from "moment";
import PageHead from "../../components/pageHead/pageHead";
import {Spin} from "antd";

const Vocabularies = (props) => {
    const {fetchPatterns, groupedPatterns, userPatterns} = props;

    useEffect(() => {
        fetchPatterns()
    }, []);

    const partHtml = (part, index) => {
        const maxPatterns = PATTERNS_GROUP_SIZE;
        const level = index + 1;
        const selectedPartDate = userPatterns[level] ? moment(userPatterns[level]).fromNow() : "Girilmedi";
        return (
            <Link className="part" to={`${PATTERNS}/${level}`} key={index}>
                <div className="left">
                    <div className="circle"></div>
                </div>
                <div className="middle">
                    <div className="level">Seviye {level}</div>
                    <div className="info">{maxPatterns} Cümle</div>
                </div>
                <div className="right">
                    {selectedPartDate}
                </div>
            </Link>
        );
    }
    return (
        <>
            {
                groupedPatterns.length > 0
                    ?
                    <div className="patterns">
                        <PageHead data={{
                            "img": "/img/video-lesson.png",
                            "title": "Kalıplaşmış İfadeler",
                            "desc": "İngilizce dilinde sıkça kullanılan ve özel anlamları olan ifadeleri öğrenebilirsiniz."
                        }}/>

                        <div className="content-wrapper">
                            {
                                groupedPatterns.map((part, index) => partHtml(part, index))
                            }
                        </div>
                    </div>
                    : <Spin className="wt-spin"/>
            }
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        activeCourse: state.userCoursesReducer.activeCourse,
        groupedPatterns: state.patternsReducer.groupedPatterns,
        userPatterns: state.patternsReducer.userPatterns,
        userLevel: state.levelsReducer.userLevel,
    }
}

const mapActionsToProps = {
    fetchPatterns
}

export default connect(mapStateToProps, mapActionsToProps)(Vocabularies);