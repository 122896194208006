import React, {useEffect} from 'react';
import './community.scss';
import CommunityComponent from "../../components/dashboard/community/community";

const Community = () => {
    useEffect(() => {
        document.body.classList.add("others-menus");
        return () => {
            document.body.classList.remove("others-menus");
        };
    }, []);
    return (
        <div className="community-page">
            <div className="content-wrapper">
                <CommunityComponent/>
            </div>

        </div>
    );
};

export default Community;
