import userReducer from './userReducer'
import levelsReducer from './levelsReducer'
import userCoursesReducer from './userCourseReducer'
import topicsReducer from './topicsReducer'
import vocabulariesReducer from './vocabulariesReducer'
import patternsReducer from './patternsReducer'
import testsReducer from './testsReducer'
import {combineReducers} from 'redux'

const rootReducer = combineReducers({
    userReducer,
    levelsReducer,
    userCoursesReducer,
    topicsReducer,
    vocabulariesReducer,
    patternsReducer,
    testsReducer
})

export default rootReducer
