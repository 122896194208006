import React, {useEffect, useState} from 'react';
import './accountSettings.scss';
import {connect} from "react-redux";
import Profile from "../../components/dashboard/profile/profile";
import {Tabs, Button, Form, Input, Radio, Modal} from 'antd';
import {updatePassword, updateProfile} from "../../redux/actions/userAction";

const AccountSettings = (props) => {
    const {updateProfile, user, updatePassword} = props;
    const [form] = Form.useForm();
    const [formLayout, setFormLayout] = useState('horizontal');
    const [firstName, setFirstName] = useState(user.name);
    const [lastName, setLastName] = useState(user.surname);
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');

    const handleInputChange = (setStateFunction) => (event) => {
        setStateFunction(event.target.value);
    };

    const infoUpdate = async () => {
        if (firstName && lastName) {
            await updateProfile(firstName, lastName);
            Modal.success({
                title: 'Başarılı',
                content: 'Profil bilgileriniz güncellenmiştir.',
                maskClosable: true,
            });
        }
        else {
            Modal.warning({
                title: 'Dikkat',
                content: 'Lütfen tüm alanları doldurun.',
                maskClosable: true,
            });
        }
    }

    const passwordUpdate = async() => {
        if ((password && password2) && (password === password2)) {
            await updatePassword(password);
            Modal.success({
                title: 'Başarılı',
                content: 'Şifreniz başarıyla değiştirilmiştir.',
                maskClosable: true,
            });
        }
        else {
            Modal.warning({
                title: 'Dikkat',
                content: 'Alanların dolu olduğundan ve şifrelerin eşleştiğinden emin olun.',
                maskClosable: true,
            });
        }
    }

    const items = [
        {
            key: '1',
            label: `Kullanıcı bilgileri`,
            children: (<>
                <Form layout={"vertical"} size="large" initialValues={{firstName: firstName, lastName: lastName}}>
                    <div className="custom-row">
                        <Form.Item label="Ad" name="firstName">
                            <Input placeholder="Ad" value={firstName} onChange={handleInputChange(setFirstName)}/>
                        </Form.Item>
                        <Form.Item label="Soyad" name="lastName">
                            <Input placeholder="Soyad" value={lastName} onChange={handleInputChange(setLastName)}/>
                        </Form.Item>
                    </div>
                    <Button type="primary" htmlType="submit" onClick={() => infoUpdate()}>Kaydet</Button>
                </Form>
            </>),
        },
        {
            key: '2',
            label: `Şifre Değiştir`,
            children: (<>
                <Form layout={"vertical"} size="large">
                    <div className="custom-row">
                        <Form.Item label="Şifre" name="password">
                            <Input.Password placeholder="Şifre" value={password} onChange={handleInputChange(setPassword)}/>
                        </Form.Item>
                        <Form.Item label="Şifre Tekrar" name="password2">
                            <Input.Password placeholder="Şifre Tekrar" value={password2} onChange={handleInputChange(setPassword2)}/>
                        </Form.Item>
                    </div>
                    <Button type="primary" htmlType="submit" onClick={() => passwordUpdate()}>Kaydet</Button>
                </Form>
            </>),
        }
    ];

    return (
        <div className="account-settings">
            <div className="container">
                <div className="left">
                    <Profile/>
                </div>
                <div className="right">
                    <div className="content-wrapper">
                        <div className="title">
                            Profili Düzenle
                        </div>
                        <div className="content">
                            <Tabs defaultActiveKey="1" items={items}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.userReducer.user,
    }
}

const mapActionsToProps = {
    updateProfile,
    updatePassword
}

export default connect(mapStateToProps, mapActionsToProps)(AccountSettings);