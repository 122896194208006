import React, {useEffect, useState} from 'react';
import './testsMake.scss';
import {connect} from "react-redux";
import {Badge, Button, Modal, Progress, Spin} from "antd";
import {TESTS} from "../../commons/router";
import {useParams, useNavigate} from 'react-router-dom';
import {ExclamationCircleFilled} from "@ant-design/icons";
import {Icon} from '@iconify/react';
import {fetchTests, makeTest} from "../../redux/actions/testsAction";
import {shuffleArray, shuffleObject} from "../../commons/helpers";

const TestsMake = (props) => {
    const {fetchTests, tests, testQuestions, makeTest} = props;
    const navigate = useNavigate();
    const params = useParams();
    const selectedTestId = params.id;
    const [selectedTest, setSelectedTest] = useState("");
    const [selectedQuestion, setSelectedQuestion] = useState("");
    const [allQuestionsIds, setAllQuestionsIds] = useState("");
    const [trueCount, setTrueCount] = useState(0);
    const [falseCount, setFalseCount] = useState(0);
    const [selectedAnswer, setSelectedAnswer] = useState("");
    const [selectedAnswerStatus, setSelectedAnswerStatus] = useState("");
    const [progressBar, setProgressBar] = useState({});
    const [isClicked, setIsClicked] = useState(false);

    useEffect(() => {
        fetchTests();
    }, []);


    useEffect(() => {
        if (tests.length > 0) {
            for (let i = 0; i < tests.length; i++) {
                const test = tests[i];
                if (test.id.toString() === selectedTestId.toString()) {
                    setSelectedTest(test);
                    document.title = test.name + " testi";
                    const testQuestions = test.test_questions ? JSON.parse(test.test_questions) : [];

                    setAllQuestionsIds(shuffleArray(testQuestions));
                    const maxPart = testQuestions.length;
                    const percentageIncrease = 100 / maxPart;
                    setProgressBar({maxPart: testQuestions.length, nowPart: 0, percentageIncrease: percentageIncrease})
                    break;
                }
            }
        }
    }, [tests]);

    useEffect(() => {
        const nowPart = progressBar.nowPart;
        if (nowPart >= 0) {
            const selectedQuestionId = allQuestionsIds[nowPart];
            const question = Object.assign({}, testQuestions[selectedQuestionId]);//obje kopyalar
            const answers = question.answers ? JSON.parse(question.answers) : [];
            question["answers"] = answers;
            question["correct_answer"] = answers.correct_answer;
            delete answers["correct_answer"];
            question["answers"] = shuffleObject(question.answers);
            setSelectedQuestion(question);
            setSelectedAnswer("");
        }
    }, [progressBar]);

    const checkItAnswer = () => {
        if (selectedAnswer && !isClicked) {
            setIsClicked(true);
            const correctAnswer = selectedQuestion.correct_answer === selectedAnswer
            setSelectedAnswerStatus(correctAnswer);
            setTimeout(() => {
                setProgressBar({...progressBar, nowPart: progressBar.nowPart + 1})
                setSelectedAnswer("");
                setSelectedAnswerStatus("");
                setIsClicked(false);

                makeTest(selectedTest.id, selectedQuestion.id, selectedAnswer)
                if (correctAnswer) {
                    setTrueCount(trueCount + 1);
                } else {
                    setFalseCount(falseCount + 1);
                }
            }, 750);
        }
    }

    const closeVocabularies = () => {
        Modal.confirm({
            title: "Ayrılmak istediğine emin misin?",
            icon: <ExclamationCircleFilled/>,
            maskClosable: true,
            onOk() {
                navigate(`${TESTS}`)
            }
        });
    }

    const testHtml = () => {
        return (
            <>

                {
                    selectedQuestion &&
                    (
                        progressBar.nowPart === progressBar.maxPart ?
                            <div className="tests-make completed">
                                <div className="content-wrapper">
                                    <div className="middle">
                                        <div className="content">
                                            <img src="/img/completed.png"/>
                                            <h3>Test tamamlandı</h3>
                                            <p>Başarılı bir şekilde testi tamamladınız. İngilizce becerilerinizi ilerletmeye devam ederek test çözmeye devam edin.</p>
                                        </div>
                                        <div className="counts">
                                            <Badge count={`${trueCount} Doğru`} style={{backgroundColor: '#52c41a'}}/>
                                            <Badge count={`${falseCount} Yanlış`}/>
                                        </div>
                                    </div>
                                    <div className="bottom">
                                        <div className="content">
                                            <Button type="primary" className="button" onClick={() => navigate(`${TESTS}`)}>
                                                Test çözmeye devam et
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="tests-make">
                                <div className="content-wrapper">
                                    <div className="top">
                                        <div className="info"><Icon icon="ic:baseline-close" onClick={() => closeVocabularies()}/><span>{selectedTest.name} testi</span></div>
                                        <div className="progress-bar">
                                            <Progress percent={progressBar.nowPart * progressBar.percentageIncrease} steps={progressBar.maxPart} showInfo={false}/>
                                        </div>
                                    </div>
                                    <div className="bottom">
                                        <div className={`content`}>
                                            <div className="question">{selectedQuestion.question}</div>
                                            <div className="answers">
                                                {
                                                    Object.keys(selectedQuestion.answers).map((key) => (
                                                        <div key={key}
                                                             className={`${(selectedAnswer && selectedAnswer === key) && `active ${selectedAnswerStatus + "-answer"}`}`}
                                                             onClick={() => setSelectedAnswer(isClicked ? selectedAnswer : key)}>
                                                            {selectedQuestion.answers[key]}
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                            <Button type="primary" className="button" onClick={() => checkItAnswer()} disabled={(!selectedAnswer || isClicked) ? true : false}>
                                                {
                                                    isClicked ? <Spin/> : "Kontrol Et"
                                                }
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    )
                }
            </>
        )
    }

    return (
        <>
            {
                selectedTest
                    ?
                    testHtml()
                    :
                    <Spin className="wt-spin"/>
            }
        </>
    );
};

const mapStateToProps = (state) => {
    const testReducer = state.testsReducer;
    return {
        tests: testReducer.tests,
        userTests: testReducer.userTests,
        testQuestions: testReducer.testQuestions,
    }
}

const mapActionsToProps = {
    fetchTests,
    makeTest
}

export default connect(mapStateToProps, mapActionsToProps)(TestsMake);