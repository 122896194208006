import React, {useState} from 'react';
import {Button, Checkbox, Form, Input} from 'antd';
import './register.scss';
import {connect} from "react-redux";
import {registerWithForm} from "../../redux/actions/authAction";
import {Link} from 'react-router-dom'


const Register = (props) => {
    const [errors, setErrors] = useState("");

    const onFinish = async ({name, email, password}) => {


        try {
            const response = await props.registerWithForm({name, email, password});
            setErrors("");

        } catch (e) {
            const errors = e.response.data?.errors;
            let newErrors = {};
            if (Object.keys(errors).length > 0) {
                for (const errorKey in errors) {
                    newErrors[errorKey] = errors[errorKey][0];
                }
                setErrors(newErrors);
            } else {
                setErrors("");
            }

        }
    };

    const validateMessages = {
        required: "'${name}' is required2121!",
        // ...
    };
    return (
        <div className="login-form">
            <h1>Register Page</h1>
            <Form
                validateMessages={validateMessages}
                name="basic"
                onFinish={onFinish}
                autoComplete="off"
            >
                <Form.Item
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your name!',
                        }
                    ]}
                    validateStatus={errors["name"] ? 'error' : ''}
                    help={errors["name"]}
                >
                    <Input placeholder="Name"/>
                </Form.Item>

                <Form.Item
                    name="email"
                    rules={[
                        {
                            type: "email",
                            required: true,
                            message: 'Please input your email!',
                        },
                    ]}
                    validateStatus={errors["email"] ? 'error' : ''}
                    help={errors["email"]}
                >
                    <Input placeholder="Email"/>
                </Form.Item>

                <Form.Item
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your password!',
                        },
                    ]}
                    validateStatus={errors["password"] ? 'error' : ''}
                    help={errors["password"]}
                >
                    <Input.Password placeholder="Password"/>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
                {/*{*/}
                {/*    error &&*/}
                {/*    <p>{error}</p>*/}
                {/*}*/}
            </Form>
            <p>Hesabınız varsa <Link to="/login">Giriş Yap</Link></p>
        </div>
    );
};


const mapStateToProps = (state) => {
    return {
        user: state.userReducer.user,
    }
}

const mapActionsToProps = {
    registerWithForm
}

export default connect(mapStateToProps, mapActionsToProps)(Register);
