import React, {useEffect, useState} from 'react';
import {Timeline, Badge} from "antd";
import './dailyGuide.scss';
import {connect} from "react-redux";
import {DAILY_GUIDES} from "../../../commons/constants";
import {markUserDailyGuide} from "../../../redux/actions/userCourseAction";
import moment from "moment";
import {Link} from "react-router-dom";
import {Icon} from '@iconify/react';

const DailyGuide = (props) => {
    const {userDailyGuide, markUserDailyGuide, userLevel, innerRef} = props;
    const [guideItems, setGuideItems] = useState({});
    const [isPast, setIsPast] = useState(false);

    useEffect(() => {
        let userDailyGuideContent = userDailyGuide.content ? userDailyGuide.content : [];

        let userDailyGuideCratedAt = userDailyGuide.created_at ? userDailyGuide.created_at : "";
        if (userDailyGuideCratedAt) {
            userDailyGuideCratedAt = moment(userDailyGuideCratedAt).add(1, 'day');
            const now = moment();
            if (now.isAfter(userDailyGuideCratedAt)) { // şimdiki zaman büyükse
                userDailyGuideContent = []
                setIsPast(true);
            }
        }

        const items = [];
        let isNow = false;
        DAILY_GUIDES.forEach(function (type) {
            const isCompleted = userDailyGuideContent.includes(type.key);
            const completed = {
                dot: <Icon icon="ph:check-circle-fill"/>,
                color: "green",
                children: type.desc,
            }
            const now = {
                dot: <Icon icon="mdi:clock-time-four"/>,
                color: "blue",
                children: (
                    <>
                        <p>{type.desc}</p>
                        <Link to={`${type.url}${type.key === "topics" ? "/" + userLevel.slug : ""}`} className="info" onClick={() => updateUserGuide(type.key)}>Hemen Öğren</Link>
                    </>
                ),
            }
            const notCompleted = {
                color: "gray",
                children: type.desc,
            }

            let selectedType = notCompleted
            if (isCompleted) {
                selectedType = completed;
            } else if (!isNow) {
                selectedType = now;
                isNow = true;
            }

            items.push(selectedType)
        })
        setGuideItems(items);
    }, [userDailyGuide]);

    const updateUserGuide = (type) => {
        markUserDailyGuide(type)
    }

    return (
        <div className="daily-goals" ref={innerRef ? innerRef : null}>
            <div className="title">
                <div className="text">Günlük Öğrenim <span><b>{(userDailyGuide.content && !isPast) ? userDailyGuide.content.length : 0}</b>/{DAILY_GUIDES.length}</span></div>
            </div>
            <div className="content">
                <Timeline items={guideItems}/>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userDailyGuide: state.userCoursesReducer.userDailyGuide,
        userLevel: state.levelsReducer.userLevel,
    }
}
const mapActionsToProps = {
    markUserDailyGuide
}


export default connect(mapStateToProps, mapActionsToProps)(DailyGuide);

