import React, {useEffect, useState} from 'react';
import {Progress} from "antd";
import './statistics.scss';
import {connect} from "react-redux";
import {isMobile, isBrowser} from "react-device-detect";
import {
    ReadOutlined,
    BookOutlined,
    HighlightOutlined
} from '@ant-design/icons';

const Statistics = (props) => {
    const {userVocabularies, userTopics, allMaterialsCount, userTests} = props;
    const [statics, setStatics] = useState("");

    useEffect(() => {
        let completedTopicsCount = 0;
        for (let index in userTopics) {
            const topic = userTopics[index]
            if (topic.complete_date) {
                completedTopicsCount++;
            }
        }

        let completedTests = 0;
        for (let index in userTests) {
            const test = userTests[index]
            completedTests += Object.keys(test).length;
        }

        if (!userVocabularies["okay"]) userVocabularies["okay"] = []
        if (!userVocabularies["not_okay"]) userVocabularies["not_okay"] = []

        setStatics({
            "topics": {
                "completed": completedTopicsCount,
                "max": allMaterialsCount.topics,
                "percentage": Math.round(completedTopicsCount * 100 / allMaterialsCount.topics),
            },
            "vocabularies": {
                "completed": userVocabularies.okay.length + userVocabularies.not_okay.length,
                "max": allMaterialsCount.vocabularies,
                "percentage": Math.round((userVocabularies.okay.length + userVocabularies.not_okay.length) * 100 / allMaterialsCount.vocabularies),
            },
            "tests": {
                "completed": completedTests,
                "max": allMaterialsCount.testQuestions,
                "percentage": Math.round(completedTests * 100 / allMaterialsCount.testQuestions),
            }
        })

    }, []);

    return (
        <>
            {
                statics &&
                <div className="statistics">
                    <div className="content-wrapper">
                        <div className="title">İstatistikler</div>
                        <div className="content">
                            <div className="item">
                                <div className="item-wrapper">
                                    {
                                        isBrowser &&
                                        <div className="bar">
                                            <Progress type="circle" percent={statics.topics.percentage} strokeWidth={9} size={125} strokeColor={"#002448"} trailColor={"#DADEE7"}/>
                                        </div>
                                    }
                                    <div className="text">
                                        <div className="left">
                                            <h6>{statics.topics.completed}/{statics.topics.max}</h6>
                                            <p>Konu öğrenildi</p>
                                        </div>
                                        {
                                            isMobile &&
                                            <div className="right">
                                                <ReadOutlined/>
                                            </div>
                                        }
                                    </div>
                                    {
                                        isMobile &&
                                        <Progress percent={statics.topics.percentage}/>
                                    }
                                </div>
                            </div>
                            <div className="item">
                                <div className="item-wrapper">
                                    {
                                        isBrowser &&
                                        <div className="bar">
                                            <Progress type="circle" percent={statics.vocabularies.percentage} strokeWidth={9} size={125} strokeColor={"#AA5FBA"} trailColor={"#DADEE7"}/>
                                        </div>
                                    }
                                    <div className="text">
                                        <div className="left">
                                            <h6>{statics.vocabularies.completed}/{statics.vocabularies.max}</h6>
                                            <p>Kelime öğrenildi</p>
                                        </div>
                                        {
                                            isMobile &&
                                            <div className="right">
                                                <BookOutlined/>
                                            </div>
                                        }
                                    </div>
                                    {
                                        isMobile &&
                                        <Progress percent={statics.vocabularies.percentage}/>
                                    }
                                </div>
                            </div>
                            <div className="item">
                                <div className="item-wrapper">
                                    {
                                        isBrowser &&
                                        <div className="bar">
                                            <Progress type="circle" percent={statics.tests.percentage} strokeWidth={9} size={125} strokeColor={"#F83D7C"} trailColor={"#DADEE7"}/>
                                        </div>
                                    }
                                    <div className="text">
                                        <div className="left">
                                            <h6>{statics.tests.completed}/{statics.tests.max}</h6>
                                            <p>Test çözüldü</p>
                                        </div>
                                        {
                                            isMobile &&
                                            <div className="right">
                                                <HighlightOutlined/>
                                            </div>
                                        }
                                    </div>
                                    {
                                        isMobile &&
                                        <Progress percent={statics.tests.percentage}/>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};
const mapStateToProps = (state) => {
    return {
        userVocabularies: state.vocabulariesReducer.userVocabularies,
        userTopics: state.topicsReducer.userTopics,
        userTests: state.testsReducer.userTests,
        allMaterialsCount: state.userCoursesReducer.allMaterialsCount,
    }
}

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(Statistics);