import {createStore, applyMiddleware} from 'redux'
import thunk from 'redux-thunk'
import rootReducer from '../redux/reducers/index'
import {API_TOKEN, SET_API_TOKEN, setApiToken} from "./actions/authAction";
import Cookies from "js-cookie";

const apiTokenMiddleware = (store) => (next) => (action) => {
    if (action.type === SET_API_TOKEN) {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const urlApiToken = urlParams.get('api_token')

        let apiToken;
        if (urlApiToken) {
            apiToken = urlApiToken;
        } else {
            apiToken = Cookies.get(API_TOKEN);
        }
        return next(setApiToken(apiToken));
    }

    return next(action);
};

const store = preloadedState => createStore(
    rootReducer,
    preloadedState,
    applyMiddleware(thunk, apiTokenMiddleware)
)

export default store
