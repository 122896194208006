import {SET_COURSES, SET_ACTIVE_COURSE, UPDATE_USER_DAILY_GUIDE,SET_ALL_MATERIALS_COUNT} from "../actions/userCourseAction";

const initalState = {
    activeCourse: "",
    courses: {},
    userDailyGuide: {},
    allMaterialsCount: {}
}

const userCoursesReducer = (state = initalState, action) => {
    switch (action.type) {
        case SET_COURSES:
            return {
                ...state,
                courses: action.value
            }
        case SET_ACTIVE_COURSE:
            return {
                ...state,
                activeCourse: action.value
            }
        case UPDATE_USER_DAILY_GUIDE:
            return {
                ...state,
                userDailyGuide: action.value
            }
        case SET_ALL_MATERIALS_COUNT:
            return {
                ...state,
                allMaterialsCount: action.value
            }
        default:
            return state
    }
}
export default userCoursesReducer
