import axios from "axios";
import {API_URL, VOCABULARY_NOT_OKAY, VOCABULARY_OKAY} from "../../commons/constants";
import testsReducer from "../reducers/testsReducer";
import {updateUserVocabularies} from "./vocabulariesAction";

export const SET_TESTS = "SET_TESTS";
export const SET_TEST_QUESTIONS = "SET_TEST_QUESTIONS";
export const UPDATE_USER_TESTS = "UPDATE_USER_TESTS";

export const fetchTests = () => {
    return async (dispatch, getState) => {
        if (Object.keys(getState().testsReducer.tests).length <= 0) {

            let apiToken = getState().userReducer.apiToken;
            let response = await axios.get(API_URL + '/tests', {
                headers: {Authorization: `Bearer ${apiToken}`}
            });
            dispatch(setTests(response.data.tests));

            const testQuestions = response.data.testQuestions;
            let testQuestionsObj = {};
            testQuestions.forEach(tests => {
                testQuestionsObj[tests.id] = tests;
            });
            dispatch(setTestQuestions(testQuestionsObj));
        }
    }
}

export const setTests = (tests) => {
    return {
        type: SET_TESTS,
        value: tests
    }
}

export const setTestQuestions = (testQuestions) => {
    return {
        type: SET_TEST_QUESTIONS,
        value: testQuestions
    }
}

export const setUserTests = (userTests) => {
    return async (dispatch, getState) => {
        userTests = userTests ? JSON.parse(userTests) : {};
        dispatch(updateUserTests(userTests))
    }
}


export const updateUserTests = (userTests) => {
    return {
        type: UPDATE_USER_TESTS,
        value: userTests
    }
}


export const makeTest = (testId, questionId, answer) => {
    return async (dispatch, getState) => {
        const response =await axios.post(API_URL + '/update-user-tests-answer', {test_id: testId, question_id: questionId, answer: answer}, {
            headers: {Authorization: `Bearer ${getState().userReducer.apiToken}`}
        });
        dispatch(setUserTests(response.data.user_course_tests))
    }
}
