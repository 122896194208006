import axios from "axios";
import {API_URL, VOCABULARIES_GROUP_SIZE, VOCABULARY_NOT_OKAY, VOCABULARY_OKAY, VOCABULARY_REMAINING} from "../../commons/constants";

export const SET_VOCABULARIES = "SET_VOCABULARIES";
export const SET_GROUPED_VOCABULARIES = "SET_GROUPED_VOCABULARIES";
export const UPDATE_VOCABULARIES = "UPDATE_VOCABULARIES";
export const UPDATE_USER_VOCABULARIES = "UPDATE_USER_VOCABULARIES";

export const fetchVocabularies = () => {
    return async (dispatch, getState) => {
        let vocabularies = getState().vocabulariesReducer.vocabularies;
        if (Object.keys(vocabularies).length <= 0) {
            vocabularies = await axios.get(API_URL + '/vocabularies', {
                headers: {Authorization: `Bearer ${getState().userReducer.apiToken}`}
            });
            vocabularies = vocabularies.data;
        }

        const know = getState().vocabulariesReducer.userVocabularies;

        const vocabulariesWithKeys = {};
        const size = VOCABULARIES_GROUP_SIZE;

        const groupedData = vocabularies.reduce((acc, cur, i) => {
            if (i % size === 0) {
                acc.push([]);
            }
            const lastGroup = acc[acc.length - 1];
            lastGroup.push(cur);

            vocabulariesWithKeys[cur.id] = cur;
            return acc;
        }, []);

        const groups = groupedData.map(group => {
            return group.reduce((acc, cur) => {
                if (know[VOCABULARY_OKAY].includes(cur.id)) {
                    acc[VOCABULARY_OKAY].push(cur);
                }
                else if (know[VOCABULARY_NOT_OKAY].includes(cur.id)) {
                    acc[VOCABULARY_NOT_OKAY].push(cur);
                }
                else {
                    acc.remaining.push(cur);
                }
                return acc;
            }, {[VOCABULARY_REMAINING]: [], [VOCABULARY_OKAY]: [], [VOCABULARY_NOT_OKAY]: []});
        });

        dispatch(setVocabularies(vocabularies))
        dispatch(setGroupedVocabularies(groups))
    }
}

export const setVocabularies = (vocabularies) => {
    return {
        type: SET_VOCABULARIES,
        value: vocabularies
    }
}

export const setGroupedVocabularies = (vocabularies) => {
    return {
        type: SET_GROUPED_VOCABULARIES,
        value: vocabularies
    }
}

export const markVocabulary = (vocabularyId, status) => {
    return async (dispatch, getState) => {
        const vocabularies = getState().vocabulariesReducer.userVocabularies;

        if (status === VOCABULARY_OKAY) {
            if (!vocabularies[VOCABULARY_OKAY].includes(vocabularyId)) {
                vocabularies[VOCABULARY_OKAY].push(vocabularyId);
            }
            if (vocabularies[VOCABULARY_NOT_OKAY].includes(vocabularyId)) {
                vocabularies[VOCABULARY_NOT_OKAY].splice(vocabularies[VOCABULARY_NOT_OKAY].indexOf(vocabularyId), 1);
            }
        }
        else if (status === VOCABULARY_NOT_OKAY) {
            if (!vocabularies[VOCABULARY_NOT_OKAY].includes(vocabularyId)) {
                vocabularies[VOCABULARY_NOT_OKAY].push(vocabularyId);
            }
            if (vocabularies[VOCABULARY_OKAY].includes(vocabularyId)) {
                vocabularies[VOCABULARY_OKAY].splice(vocabularies[VOCABULARY_OKAY].indexOf(vocabularyId), 1);
            }
        }

        dispatch(updateUserVocabularies(vocabularies));


        await axios.post(API_URL + '/mark-vocabulary', {id: vocabularyId, status: status}, {
            headers: {Authorization: `Bearer ${getState().userReducer.apiToken}`}
        });
    }
}


export const setUserVocabularies = (vocabularies) => {
    return async (dispatch, getState) => {
        vocabularies = vocabularies ? JSON.parse(vocabularies) : {};
        if (!vocabularies[VOCABULARY_OKAY]) vocabularies[VOCABULARY_OKAY] = [];
        if (!vocabularies[VOCABULARY_NOT_OKAY]) vocabularies[VOCABULARY_NOT_OKAY] = [];

        dispatch(updateUserVocabularies(vocabularies));
    }
}

export const updateUserVocabularies = (vocabularies) => {
    return {
        type: UPDATE_USER_VOCABULARIES,
        value: vocabularies
    }
}


