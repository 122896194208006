import {SET_LEVELS, UPDATE_USER_LEVEL} from "../actions/levelsAction";

const initalState = {
    userLevel: {},
    levels: [],
}

const levelsReducer = (state = initalState, action) => {
    switch (action.type) {
        case SET_LEVELS:
            return {
                ...state,
                levels: action.value
            }
        case UPDATE_USER_LEVEL:
            return {
                ...state,
                userLevel: {
                    ...action.value
                }
            }
        default:
            return state
    }
}
export default levelsReducer
