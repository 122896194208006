import axios from "axios";
import {API_URL, COOKIE_DOMAIN, OUTER_PAGE_URL} from "../../commons/constants";
import {API_TOKEN} from "./authAction";
import Cookies from "js-cookie";
import {logout} from "../../commons/common";

export const SET_USER = "SET_USER";
export const SET_TOUR_STATUS = "SET_TOUR_STATUS";

export const fetchUser = () => {
    return async (dispatch, getState) => {
        try {
            const apiToken = getState().userReducer.apiToken;
            if (apiToken) {
                let response = await axios.get(API_URL + '/user', {
                    headers: {Authorization: `Bearer ${apiToken}`}
                });
                dispatch(setUser(response.data));
            } else {
                logout();
            }
        } catch (err) {
            const errorMessage = err.response.data.message; // Hata mesajını alma
            if (errorMessage === "Unauthenticated.") {
                logout();
            }
        }
    }
}


export const updateProfile = (name, surname) => {
    return async (dispatch, getState) => {

        let response = await axios.post(API_URL + '/update-user-profile', {name, surname}, {
            headers: {Authorization: `Bearer ${getState().userReducer.apiToken}`}
        });
        dispatch(setUser(response.data));

        return true;
    }
}

export const updatePassword = (password) => {
    return async (dispatch, getState) => {
        await axios.post(API_URL + '/update-user-password', {password}, {
            headers: {Authorization: `Bearer ${getState().userReducer.apiToken}`}
        });

        return true;
    }
}

export const setUser = (user) => {
    return {
        type: SET_USER,
        value: user
    }
}

export const setTourStatus = (status) => {
    return {
        type: SET_TOUR_STATUS,
        value: status
    }
}

