import React, {useEffect, useState} from 'react';
import {isMobile, isBrowser} from 'react-device-detect';
import Header from "../header/header";
import TopNavigationBar from "../mobile/topNavigationBar/topNavigationBar";
import BottomNavigationBar from "../mobile/bottomNavigationBar/bottomNavigationBar";
import {useLocation, useParams} from 'react-router-dom';
import {PAGE_INFORMATION} from "../../commons/router";
import {IS_MOBILE_APP} from "../../commons/constants";
import {setCookie} from "../../commons/common";

const General = () => {
    const [pageInformation, setPageInformation] = useState("");
    const [routes, setRoutes] = useState([]);
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);
    const isMobileApp = searchParams.get(IS_MOBILE_APP);

    useEffect(() => {
        if (parseInt(isMobileApp) === 1) {
            setCookie(IS_MOBILE_APP, 1);
        }
    }, []);

    useEffect(() => {
        updatePageInformation()
    }, [location]);

    const updatePageInformation = () => {
        try {
            const pathname = location.pathname;
            setRoutes([...routes, pathname]);

            const splitPathname = pathname.split("/");
            const page = PAGE_INFORMATION["/" + splitPathname[1]] ?? "";

            if (page) {
                document.title = page.title;
                setPageInformation(page);
            }
        } catch (err) {
            console.log(err.message)
        }
    }

    return (
        <>
            {
                isBrowser &&
                <Header pageInformation={pageInformation}/>
            }
            {
                isMobile &&
                <>
                    <TopNavigationBar pageInformation={pageInformation}/>
                    <BottomNavigationBar/>
                </>
            }
        </>
    );
};

export default General;
