import {useState, useEffect} from 'react';
import './bottomNavigtaionBar.scss';
import {connect} from "react-redux";
import {Button, Space} from 'antd';
import {
    HomeOutlined,
    ReadOutlined,
    LineChartOutlined,
    MenuOutlined
} from '@ant-design/icons';
import {Link, useLocation} from "react-router-dom";
import {DASHBOARD, LEVELS, STATISTICS, OTHER_MENUS} from "../../../commons/router";

const BottomNavigationBar = () => {
    const location = useLocation();
    const pathname = location.pathname;


    return (
        <div className="bottom-navigation-bar">
            <Link to={DASHBOARD}>
                <Button className={pathname === DASHBOARD ? "active" : ""}>
                    <HomeOutlined/>
                    Ana Sayfa
                </Button>
            </Link>
            <Link to={LEVELS}>
                <Button className={pathname.includes(LEVELS) ? "active" : ""}>
                    <ReadOutlined/>
                    Kurslar
                </Button>
            </Link>
            <Link to={STATISTICS}>
                <Button className={pathname.includes(STATISTICS) ? "active" : ""}>
                    <LineChartOutlined/>
                    İstatistikler
                </Button>
            </Link>
            <Link to={OTHER_MENUS}>
                <Button className={pathname.includes(OTHER_MENUS) ? "active" : ""}>
                    <MenuOutlined/>
                    Diğer
                </Button>
            </Link>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {}
}

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(BottomNavigationBar);