import React, {useEffect, useRef, useState} from 'react';
import {Button} from 'antd';
import './header.scss';
import {DASHBOARD, LEVELS, FEEDBACK, COMMUNITY} from "../../commons/router";
import {Link, useLocation} from "react-router-dom";
import {logout} from "../../commons/common";
import { Icon } from '@iconify/react';

const Header = () => {
    const location = useLocation();
    const headerRef = useRef();
    const pathname = location.pathname;
    const [showBanner, setShowBanner] = useState(true);

    useEffect(() => {
        if (headerRef.current && pathname.includes("/lesson/")) document.body.classList.add("lesson"); //lesson routunda header gizle
        else document.body.classList.remove("lesson");
        if (headerRef.current && pathname.includes("/vocabularies/")) document.body.classList.add("vocabularies-learn"); //vocabularies routunda header gizle
        else document.body.classList.remove("vocabularies-learn");

        if (headerRef.current && pathname.includes("/tests/")) document.body.classList.add("tests-make"); //vocabularies routunda header gizle
        else document.body.classList.remove("tests-make");
    }, [location]);


    useEffect(() => {
        const isBannerClosed = localStorage.getItem('bannerClosed');
        if (isBannerClosed) {
            setShowBanner(false);
        }
    }, []);

    const handleBannerClose = () => {
        localStorage.setItem('bannerClosed', true);
        setShowBanner(false);
    };


    return (
        <>
            {/*{showBanner && (*/}
            {/*    <div className="banner">*/}
            {/*        <p><b>Beta versiyona hoş geldiniz!</b> Geliştirme aşamasında olduğumuz için geri bildirimleriniz bize çok yardımcı olacaktır.</p>*/}
            {/*        <div className="close" onClick={handleBannerClose}><Icon icon="material-symbols:close"/></div>*/}
            {/*    </div>*/}
            {/*)}*/}

            <header className="header" ref={headerRef}>
                <div className="content-wrapper">
                    <div className="left">
                        <Link to={DASHBOARD}> <img src="/img/logo.png" alt="logo"/>safelyenglish</Link>
                    </div>
                    <div className="middle">
                        <ul>
                            <li>
                                <Link to={DASHBOARD} className={pathname === DASHBOARD ? "active" : ""}>Öğren</Link>
                            </li>
                            <li>
                                <Link to={LEVELS} className={pathname.includes(LEVELS) ? "active" : ""}>Kurslar</Link>
                            </li>
                            <li>
                                <Link to={FEEDBACK}>Destek</Link>
                            </li>
                            <li>
                                <Link to="#" onClick={() => logout()}>Çıkış</Link>
                            </li>
                            <li>
                                <Link to={COMMUNITY}>
                                    <Button type="dashed" size="large">Topluluk</Button>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </header>
        </>
    );
};

export default Header;
