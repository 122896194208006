import React, {useEffect, useState} from 'react';
import './vocabularies.scss';
import {fetchVocabularies} from "../../redux/actions/vocabulariesAction";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {VOCABULARIES} from "../../commons/router";
import {VOCABULARIES_TYPES, VOCABULARIES_GROUP_SIZE} from "../../commons/constants";
import {Progress, Button, Modal, Spin} from 'antd';
import PageHead from "../../components/pageHead/pageHead";

const Vocabularies = (props) => {
    const {fetchVocabularies, groupedVocabularies, userLevel} = props;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedLevel, setSelectedLevel] = useState("");

    useEffect(() => {
        fetchVocabularies();
    }, []);

    const updateSelectedLevel = (level, index) => {
        level["index"] = index;
        setSelectedLevel(level);
        setModal(true);
    }

    const setModal = (value) => {
        setIsModalOpen(value);
    };

    const partHtml = (part, index) => {
        const allWordsCount = part.not_okay.length + part.okay.length + part.remaining.length;
        const maxWords = allWordsCount < VOCABULARIES_GROUP_SIZE ? allWordsCount : VOCABULARIES_GROUP_SIZE;
        const okayWords = part.okay.length;
        const percentage = 100 * okayWords / maxWords;
        return (
            <div className="part" key={index} onClick={() => updateSelectedLevel(part, index + 1)}>
                <div className="left">
                    <div className="circle"></div>
                </div>
                <div className="middle">
                    <div className="level">Seviye {index + 1}</div>
                    <div className="info">Tamamlanan {okayWords}/{maxWords}</div>
                </div>
                <div className="right">
                    <Progress type="circle" percent={percentage} size={50}/>
                </div>
            </div>
        );
    }

    return (
        groupedVocabularies.length > 0
            ?
            <div className="vocabularies">
                {
                    selectedLevel &&
                    <Modal title={`${selectedLevel.index}. Seviye Kelimeler`} className="partModal" open={isModalOpen} onOk={() => setModal(false)} onCancel={() => setModal(false)} footer={null} closable={false}>
                        {Object.entries(VOCABULARIES_TYPES).map(([key, value]) => (
                            selectedLevel[key].length > 0
                                ?
                                <Link to={`${VOCABULARIES}/${selectedLevel.index}/${key}`} key={key}>
                                    <Button type="primary" className="button">{value.title} {selectedLevel[key].length}</Button>
                                </Link>
                                :
                                <Button type="primary" className="button" disabled={true} key={key}>{value.title} {selectedLevel[key].length}</Button>

                        ))}
                    </Modal>
                }
                <PageHead data={{
                    "img": "/img/words.png",
                    "title": "Kelimeler",
                    "desc": "Kelime kartlarıyla yeni kelimeler öğrenme özelliğimizle, İngilizce kelime dağarcığınızı hızlı ve etkili bir şekilde genişletebilirsiniz."
                }}/>
                <div className="content-wrapper">
                    {
                        groupedVocabularies.map((part, index) => partHtml(part, index))
                    }
                </div>
            </div>
            : <Spin className="wt-spin"/>
    );
};

const mapStateToProps = (state) => {
    return {
        groupedVocabularies: state.vocabulariesReducer.groupedVocabularies,
        userLevel: state.levelsReducer.userLevel,
    }
}

const mapActionsToProps = {
    fetchVocabularies,
}

export default connect(mapStateToProps, mapActionsToProps)(Vocabularies);