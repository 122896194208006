import React, {useState, useEffect} from 'react';
import './lesson.scss';
import {Button, Progress, Modal, Spin} from 'antd';
import {Icon} from '@iconify/react';
import {ExclamationCircleFilled} from "@ant-design/icons";
import {DASHBOARD, TESTS, TOPICS} from "../../commons/router";
import {useParams, useNavigate} from 'react-router-dom';
import {fetchTopicContent, fetchTopics} from "../../redux/actions/topicsActions";
import {connect} from "react-redux";
import {findTopicBySlug} from "../../commons/helpers";
import {doneContent} from "../../redux/actions/userCourseAction";

const Lesson = (props) => {
    const {fetchTopicContent, topicsContent, topics, fetchTopics, doneContent, userLevel} = props;
    const params = useParams();
    const navigate = useNavigate();
    const [progressBar, setProgressBar] = useState({});
    const [allContents, setAllContents] = useState([]);
    const [selectedContent, setSelectedContent] = useState("");
    const [selectedTopic, setSelectedTopic] = useState("");
    const [load, setLoad] = useState(true);

    useEffect(() => {
        document.body.classList.add("lesson");

        if (Object.keys(topics).length <= 0) {
            fetchTopics();
        }

        return () => {
            document.body.classList.remove("lesson");
        };
    }, []);

    const getContents = async () => {
        if (Object.keys(topics).length > 0) {
            const findedTopic = findTopicBySlug(topics, params.lesson);
            setSelectedTopic(findedTopic);
            document.title = findedTopic.title;

            let allContents = "";
            if (topicsContent[findedTopic.id]) {
                allContents = topicsContent[findedTopic.id];
            } else {
                const responseContent = await fetchTopicContent(params.lesson);
                allContents = responseContent;
            }

            const firstContent = allContents[0];
            if (firstContent) {
                setSelectedContent(firstContent);
                doneContent(findedTopic.id, firstContent.id)
            }

            setLoad(false);
            setAllContents(allContents);
        }
    }

    useEffect(() => {
        getContents()
    }, [topics]);

    useEffect(() => {
        if (allContents[progressBar.nowPart]) {
            setSelectedContent(allContents[progressBar.nowPart]);
        }

        if (progressBar.nowPart > 0) {
            doneContent(selectedTopic.id, selectedContent.id)
        }
    }, [progressBar.nowPart]);

    useEffect(() => {
        const maxPart = allContents.length;
        const percentageIncrease = 100 / maxPart;
        setProgressBar({maxPart: maxPart, nowPart: 0, percentageIncrease: percentageIncrease})
    }, [allContents]);

    const closeLesson = () => {
        Modal.confirm({
            title: "Bu dersten ayrılmak istediğine emin misin?",
            icon: <ExclamationCircleFilled/>,
            maskClosable: true,
            onOk() {
                navigate(`${TOPICS}/${userLevel.slug}`)
            }
        });
    }

    return (
        <>
            {
                load
                    ?
                    <Spin className="wt-spin"/>
                    :
                    selectedContent ?
                        progressBar.nowPart === progressBar.maxPart ?
                            (
                                <div className="lesson completed">
                                    <div className="top">
                                        <div className="progress-bar">
                                            <Progress percent={progressBar.nowPart * progressBar.percentageIncrease} steps={progressBar.maxPart}/>
                                        </div>
                                    </div>
                                    <div className="middle">
                                        <div className="content">
                                            <img src="/img/completed.png"/>
                                            <h3>Ders Tamamlandı</h3>
                                            <p>Dersini tamamladın daha fazla ders öğrenerek İngilizce eğitimini devam ettir</p>
                                        </div>
                                    </div>
                                    <div className="bottom">
                                        <div className="content">
                                            <Button type="default" className="button go-tests-btn" onClick={() => navigate(TESTS)}>
                                                Test Çözmeye Başla
                                            </Button>
                                            <Button type="primary" className="button" onClick={() => navigate(`${TOPICS}/${userLevel.slug}`)}>
                                                Eğitime Devam Et
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            )
                            :
                            <div className="lesson">
                                <div className="top">
                                    <div className="info"><Icon icon="ic:baseline-close" onClick={() => closeLesson()}/><span>{selectedTopic.title}</span></div>
                                    <div className="progress-bar">
                                        <Progress percent={progressBar.nowPart * progressBar.percentageIncrease} steps={progressBar.maxPart}/>
                                    </div>
                                </div>
                                <div className="middle">
                                    <div className="content" dangerouslySetInnerHTML={{__html: selectedContent.content}}></div>
                                    {/*<img src="https://i.hizliresim.com/bzznhxr.png"/>*/}
                                </div>
                                <div className="bottom">
                                    <div className="content">
                                        <Button type="primary" className="button" disabled={progressBar.nowPart > 0 ? false : true} ghost onClick={() => setProgressBar({...progressBar, nowPart: progressBar.nowPart - 1})}>
                                            Geri
                                        </Button>

                                        <Button type="primary" className="button" disabled={(progressBar.nowPart !== 0 && progressBar.nowPart === progressBar.maxPart) ? true : false} onClick={() => setProgressBar({...progressBar, nowPart: progressBar.nowPart + 1})}>
                                            Devam
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        : (
                            <div className="lesson">
                                <div className="top">
                                    <div className="info"><Icon icon="ic:baseline-close" onClick={() => closeLesson()}/><span>İçerik Bulunamadı</span></div>
                                </div>
                            </div>
                        )
            }
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        topicsContent: state.topicsReducer.topicsContent,
        topics: state.topicsReducer.topics,
        userLevel: state.levelsReducer.userLevel,
    }
}

const mapActionsToProps = {
    fetchTopicContent,
    fetchTopics,
    doneContent
}

export default connect(mapStateToProps, mapActionsToProps)(Lesson);