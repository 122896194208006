import {useState, useEffect} from 'react';
import './topNavigtaionBar.scss';
import {connect} from "react-redux";
import {Icon} from '@iconify/react';
import {Button} from "antd";
import {useLocation, useNavigate} from 'react-router-dom';
import {BACK_BUTTON_HIDDEN_ROUTES} from "../../../commons/router";

const TopNavigationBar = (props) => {
    const {pageInformation} = props;
    const [hiddenBackButton, setHiddenBackButton] = useState(false);
    const [selectedTitle, setSelectedTitle] = useState("");
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (BACK_BUTTON_HIDDEN_ROUTES.includes(location.pathname)) {
            setHiddenBackButton(true);
        } else {
            setHiddenBackButton(false);
        }

        updateTitle();
    }, [location]);


    //title değişimini tespit etmek için
    const observer = new MutationObserver(([{ target }]) =>
        setSelectedTitle(target.text),
    )
    observer.observe(document.querySelector('title'), {
        childList: true,
    })

    const handleBackButtonClick = () => {
        navigate(-1); // Geçmişte bir önceki sayfaya gitmek için navigate fonksiyonuna -1 parametresi gönderin
    };


    const updateTitle = () => {
        setTimeout(function () {
            setSelectedTitle(document.title)
        })
    }

    return (
        <div className="top-navigation-bar">
            {
                !hiddenBackButton &&
                <Button className="back-button" onClick={handleBackButtonClick}>
                    <Icon icon="tabler:arrow-left"/>
                </Button>
            }
            {selectedTitle}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {}
}

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(TopNavigationBar);