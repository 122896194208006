import React, {useEffect, useState} from 'react';
import './statistics.scss';
import {connect} from "react-redux";

import StatisticsComponent from "../../components/dashboard/statistics/statistics";

const Statistics = (props) => {


    return (
        <div className="statistics-page">
            <StatisticsComponent/>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {}
}

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(Statistics);